import {
  TranslateService
} from "@ngx-translate/core";
import * as jp from "jsonpath";

import * as Config from "../../util/config.constants";
import {
  copyObject
} from "../../util/object-utils";
import {
  EMPTY
} from "../../util/string-constants";
import { Logger } from "../logging/logger";
import { BaseFormatter } from "./base-formatter";
import {
  FormatterService
} from "./formatter.service";

interface Threshold {
  absolute?: boolean;
  threshold: number;
  format: Format;
}

interface Format {
  scale?: number;
  value?: number;
  formatString?: string;
  formatter?: ((n: number) => string);
  valuePrefix?: string;
  valueSuffix?: string;
  slice?: {start: number, end: number};
  valueJSONPath?: string;
  prefix?: string;
  suffix?: string;
  absolute?: boolean;
  showPositive?: boolean;
}

const _NA_TRANSLATION_KEY = "na-translation-key";

export class SimpleValueFormatter extends BaseFormatter {

  private _default: Format;
  private _naNameRef: string;
  private _thresholds: Threshold[];
  private _compositions: string[];
  private _namedFormats: {[code: string]: Format};

  constructor(locale: d3.Locale, config: any, translateService: TranslateService, formatterService: FormatterService, logger: Logger) {
    super(locale, config, translateService, formatterService, logger);
    if (!this.config || !this.config.default || (!this.config.default.formatString && !this.config.default.value)) {
      // tslint:disable-next-line:max-line-length
      throw new Error("No configuration supplied for simple value formatter!  Either configuration was missing or one of formatString / value option was not set!");
    }
    this._default = this._loadFormat(this.config.default);
    this._naNameRef = this.config[_NA_TRANSLATION_KEY];

    this._loadThresholds();

    this._loadNamedFormats();

    // Have to do this AFTER we copy the default values to
    // child formats, otherwise the copyObject method gets into
    // a horrible mess.
    this._instantiateFormatFunctions();

  }

  public formatData(datum: any, params?: any): string {
    if (null != this._compositions) {
      return this._compositionFormatData(datum, params);
    } else {
      return this._defaultFormatData(datum, params);
    }
  }

  private _compositionFormatData(datum: any, params?: any): string {
    return this._compositions.reduce((ret: string, curr: string) => {
      const fmt = this._namedFormats[curr];
      let val: number = this._getValue(fmt, datum);
      val = Number(this._sliceString(val.toString(), fmt.slice));
      const newPart: string = fmt.prefix + fmt.valuePrefix + fmt.formatter(val) + fmt.valueSuffix + fmt.suffix;
      return ret + this.translatePlaceholders(newPart);
    }, "");
  }

  private _defaultFormatData(datum: any, params?: any): string {

    if (null == datum && this._naNameRef !== undefined) {
      return  this._translateService.instant(this._naNameRef);
    }

    const fmt: Format = this._thresholdFormat(datum);
    let val: number = this._getValue(fmt, datum);
    val = Number(this._sliceString(val.toString(), fmt.slice));
    const sign = fmt.absolute === true ? '' : (val < 0  ? '-' : (fmt.showPositive === true && val > 0 ? '+' : ''));
    val = Math.abs(val);
    const ret: string = fmt.prefix + sign + fmt.valuePrefix + fmt.formatter(val) + fmt.valueSuffix + fmt.suffix;
    return this.translatePlaceholders(ret);
  }

  private _loadThresholds() {
    const toIterate = this.config[Config.THRESHOLDS] || [];
    const thresholds: Threshold[] = [];
    for (const th of toIterate) {
      thresholds.push(this._loadThreshold(th));
    }

    // Sort the thresholds in DESCENDING order so we process the highest
    // threshold first.  That way we can stop the second we get to one we match.
    // If we sorted them ascending we'd have to keep going in case we found
    // a higher threshold value that also matched which is inefficient.
    this._thresholds = thresholds.sort((a: Threshold, b: Threshold) => b.threshold - a.threshold);

  }

  private _loadNamedFormats() {
    const nfConfig = (this.config[Config.NAMED_FORMATS] || {});
    this._namedFormats = Object.keys(nfConfig).reduce((dict, key) => {
      const fmtConfig = nfConfig[key];
      const fmt = this._loadFormat(fmtConfig, this._default);
      dict[key] = fmt;
      return dict;
    }, {});

    // Now load composition data.
    this._loadCompositions();
  }

  private _loadCompositions() {
    const compositions = this.config[Config.COMPOSITIONS];
    if (null == compositions) {
      return;
    }

    compositions.forEach((curr: string) => {
      if (!(curr in this._namedFormats)) {
        // tslint:disable-next-line:max-line-length
        throw new Error(`Format composition element ${curr} is not configured in the ${Config.NAMED_FORMATS} configuration.  This will produce run time errors and must be fixed.  Cannot continue!`);
      }
    });

    this._compositions = compositions;

  }

  private _getValue(fmt: Format, datum: any): number {
    let ret: number = datum;
    if (null != fmt.valueJSONPath && null != datum) {
      ret = jp.query(datum, fmt.valueJSONPath)[0];
    }
    const reverseSign = this.config["reverse-sign"];
    if (reverseSign && datum !== 0) {
      datum *= -1;
    }
    return (fmt.absolute ? Math.abs(ret) : ret) / fmt.scale;
  }

  private _instantiateFormatFunctions() {
    this._instantiateFormatFunction(this._default);
    this._thresholds.forEach((th) => { this._instantiateFormatFunction(th.format); });
    if (null != this._namedFormats) {
      Object.keys(this._namedFormats).forEach((key: string) => {
        this._instantiateFormatFunction(this._namedFormats[key]);
      });
    }
  }

  private _instantiateFormatFunction(fmt: Format) {
    if (fmt.formatString == null && null != fmt.value) {
      fmt.formatter = (() => EMPTY + fmt.value);
    } else {
      try {
        fmt.formatter = this.locale.numberFormat(fmt.formatString);
      } catch (e) {
        // tslint:disable-next-line:max-line-length
        throw new Error("Error instantiating a formatter function using format string '" + fmt.formatString + "'!  Underlying error follows:\n" + e);
      }
    }
  }

  private _thresholdFormat(value: number): Format {
    // OK, this is pretty simple.  Just loop round the thresholds and as soon
    // as we find one where properties.value >= threshold, return colour.
    // Works this simply because the thresholds are sorted descending.

    const absValue = Math.abs(value);
    for (const curr of this._thresholds) {
      // Either the actual value is greater than or equal to this threshold
      // OR the absolute value is greater than or equal to this threshold
      // AND this threshold is configured to absolute evaluation.
      if (value >= curr.threshold || (absValue >= curr.threshold && curr.absolute === true)) {
        return curr.format;
      }
    }
    return this._default;
  }

  private _sliceString(str: string, slice: Format["slice"]): string {
    if (null == slice) {
      return str;
    }
    return str.slice(slice.start, slice.end);
  }

  private _loadThreshold(th: Threshold): Threshold {
    if (th.threshold === null) {
      throw new Error("Configured formatter threshold has no threshold value!");
    }
    th.format = this._loadFormat(th.format, this._default);

    // Default to absolute threshold checking as this is the current behaviour.
    // We only use "non absolute threshold checking" if you've explicitly set absolute to false
    // on a threshold.
    th.absolute = th.absolute === false ? th.absolute : true;
    return th;
  }

  private _copyItemsFromBaseFormat(fmt: Format, copyFrom?: Format) {
    if (fmt === null) {
      throw new Error("SimpleValueFormatter was passed a null format to use!  Don't put null values in threshold arrays or for the default format!");
    }
    if (!copyFrom) {
      return fmt;
    }

    const ret: Format = copyObject(copyFrom) as Format;

    for (const key of Object.keys(fmt)) {
      if (fmt.hasOwnProperty(key)) {
        ret[key] = fmt[key];
      }
    }
    return ret;
  }

  private _loadFormat(fmt: Format, copyFrom?: Format): Format {
    const ret: Format = this._copyItemsFromBaseFormat(fmt, copyFrom);
    if (!ret.formatString && !ret.value) {
      throw new Error("formatString and value were both un-specified for configured format!");
    }
    ret.prefix = ret.prefix || "";
    ret.suffix = ret.suffix || "";
    ret.valuePrefix = ret.valuePrefix || "";
    ret.valueSuffix = ret.valueSuffix || "";
    ret.absolute = ret.absolute;
    ret.scale = ret.scale || 1;
    return ret;
  }

}
