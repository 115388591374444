import { AppRoutes } from "../app.routes";
import {
  LayoutRoutes
} from "../layout/layout.routes";
import { User } from "../model/user/user.model";
import {
  URL_SEP
} from "../util/string-constants";
import * as UserActions from "./user.actions";
import { json2ts } from "../util/json-2ts";

export const DEFAULT_LAST_ACTIVE_ROUTE = URL_SEP + AppRoutes.Application + URL_SEP + LayoutRoutes.Dashboard + URL_SEP + 0;

export interface Auth {
  idToken?: string;
  accessToken?: string;
}

export interface UserContext {
  profile: User;
  auth: Auth;
  message?: string;
  authRequested: boolean;
  lastActiveRoute: string;
  lastActiveTimestamp: Date;
}

const initialState: UserContext = {
  profile: null,
  auth: null,
  message: null,
  authRequested: false,
  lastActiveRoute: null,
  lastActiveTimestamp: null
};

export const LOGOUT = "LOGOUT";

function _stateWithActiveTimestamp(state: UserContext) {
  return {
    ...state,
    lastActiveTimestamp: new Date(Date.now())
  };
}

export function userReducer(state = initialState, action: UserActions.UserActions): UserContext {

  state.profile = User.ToValidContextObject(state.profile);

  switch (action.type) {

    case UserActions.AUTHENTICATION_SUCCEEDED:
      return {
        ...state,
        auth: action.payload,
        authRequested: false
      };

    case UserActions.AUTHENTICATION_FAILED:
      return {
        ...state,
        message: action.payload,
        authRequested: false
      };

    case UserActions.AUTHENTICATION_COMPLETED:
      return {
        ...state,
        auth: action.payload.auth,
        profile: action.payload.profile
      };

    case UserActions.AUTHENTICATION_REQUESTED:
      return {
        ...state,
        authRequested: true,
        message: null
      };

    case UserActions.ACTIVE_ROUTE_CHANGED:
      return {
        ..._stateWithActiveTimestamp(state),
        lastActiveRoute: action.payload
      };

    case UserActions.USER_RESET:
      if (null != action.payload || action.forceClear) {
        return action.payload;
      }

      return {
        ..._stateWithActiveTimestamp(initialState),
        lastActiveRoute: state.lastActiveRoute
      };

    default:
        return state;
  }
}
