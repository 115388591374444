import {
  Alert
} from "../model/alert/alert.model";
import { ts2json } from "../util/json-2ts";
import * as OU from "../util/object-utils";
import * as UXActions from "./ux.actions";

export interface UXContext {
  selectedAlert: Alert;
  deSelectedAlert: Alert;
}

const initialState: UXContext = {
  selectedAlert: null,
  deSelectedAlert: null
};

// NxRx removes the typescript types (for some stupid reason)
// so we deserialize this back to JSON
// and then things picking it up at the other end will have to re-parse it.
// Fortunately this is not a high volume method or that would be entirely absurd...
export function uxReducer(state = initialState, action: UXActions.UXActions): UXContext {
  switch (action.type) {

    case UXActions.ALERT_SELECTED:
      return {
        ...state,
        deSelectedAlert: null,
        selectedAlert: ts2json(action.payload)
      };

    case UXActions.ALERT_DESELECTED:
      return {
        ...state,
        deSelectedAlert: ts2json(action.payload),
        selectedAlert: null
      };

    case UXActions.UX_RESET:
      return OU.copyObjectWithoutNullsOrGraphQLMetaKeys(action.context) as UXContext;

    default:
      return state;
  }
}
