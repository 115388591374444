import { Action } from "@ngrx/store";

import { ReferenceContext } from "./reference.reducers";
import { Snapshot } from '../model/snapshots/snapshot.model';

/* tslint:disable max-classes-per-file */
export const LOCATION_TYPES_CHANGED = 'LOCATION_TYPES_CHANGED';
export const ENTITIES_CHANGED = 'ENTITIES_CHANGED';
export const REGIONS_CHANGED = 'REGIONS_CHANGED';
export const LOCATION_IDS_CHANGED = 'LOCATION_IDS_CHANGED';
export const REFERENCE_DEMO_RESET = 'REFERENCE_DEMO_RESET';
export const UPDATE_REFERENCE = "UPDATE_REFERENCE";
export const ACTIVE_SNAPSHOT_CHANGED = "ACTIVE_SNAPSHOT_CHANGED";

export class  LocationTypesChanged implements Action {
    public readonly type = LOCATION_TYPES_CHANGED;
    constructor(public payload: string[]) {} // for now I take it as object later we can change this to model or any
}

export class EntitiesChanged implements Action {
  public readonly type = ENTITIES_CHANGED;
  constructor(public payload: string[]) {} // for now I take it as object later we can change this to model or any
}

export class LocationsChanged implements Action {
  public readonly type = LOCATION_IDS_CHANGED;
  constructor(public payload: string[]) {} // for now I take it as object later we can change this to model or any
}

export class RegionsChanged implements Action {
    public readonly type = REGIONS_CHANGED;
    constructor(public payload: string[]) {} // for now I take it as object later we can change this to model or any
}

export class SetReferenceContext implements Action {
  public readonly type = REFERENCE_DEMO_RESET;
  constructor(public context: ReferenceContext) {}
}

export class UpdateReference implements Action {
  public readonly type = UPDATE_REFERENCE;
  constructor(public payload: any) {}
}

export class ActiveSnapshotChanged implements Action {
  public readonly type = ACTIVE_SNAPSHOT_CHANGED;
  constructor(public payload: Snapshot) {}
}
export type ReferenceActions = EntitiesChanged | LocationTypesChanged | LocationsChanged | RegionsChanged | SetReferenceContext | UpdateReference |
ActiveSnapshotChanged;
/* tslint:enable max-classes-per-file */
