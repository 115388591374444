import {
  JsonObject,
  JsonProperty,
  Any
} from "json2typescript";

import {
  TemporalAggregation
} from "../../model/business-dates/temporal-aggregation.enum";
import * as Config from "../../util/config.constants";
import { json2ts } from "../../util/json-2ts";
import {
  TemporalAggregationArrayConverter
} from "./temporal-aggregation-converters";

const _DEFAULT_TEMPORAL_AGGREGATIONS: TemporalAggregation[] = [
  TemporalAggregation.Day,
  TemporalAggregation.Week
];

@JsonObject
export class ContextConfiguration {

  public static DEFAULT: ContextConfiguration = {
    temporalAggregations: _DEFAULT_TEMPORAL_AGGREGATIONS
  };

  public static LOADED: ContextConfiguration = ContextConfiguration.DEFAULT;
  public static init(config: any): void {
    ContextConfiguration.LOADED = json2ts(JSON.parse(config), ContextConfiguration);
  }

  @JsonProperty(Config.TEMPORAL_AGGREGATIONS, TemporalAggregationArrayConverter, true)
  public temporalAggregations: TemporalAggregation[] = _DEFAULT_TEMPORAL_AGGREGATIONS;


}
