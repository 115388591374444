import { Action } from "@ngrx/store";

import {
  Comment
} from "../model/comments/comment.model";
import { MutationType } from '../dashboard/components/config/mutation-type.enum';
import { Snapshot } from '../model/snapshots/snapshot.model';

/* tslint:disable max-classes-per-file */
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_SNAPSHOT_USING_CONTEXT = "ADD_SNAPSHOT_USING_CONTEXT";

export const MUTATION_SUCCESSFUL = "MUTATION_SUCCESSFUL";

export class AddComment implements Action {
    public readonly type = ADD_COMMENT;
    constructor(public payload: Comment) {}
}

export class AddSnapshotUsingContext implements Action {
  public readonly type = ADD_SNAPSHOT_USING_CONTEXT;
  constructor(public payload: {snapshotGUID: string}) {}
}

export class MutationSuccessful implements Action {
  public readonly type = MUTATION_SUCCESSFUL;
  public payload: MutationType[];
  constructor(...payload: MutationType[]) {
    this.payload = payload;
  }
}

export type MutationActions = AddComment | AddSnapshotUsingContext | MutationSuccessful;
/* tslint:enable max-classes-per-file */
