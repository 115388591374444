import { ActionReducer } from "@ngrx/store";
import {
  localStorageSync
} from "ngrx-store-localstorage";

import { User } from "../model/user/user.model";
import { json2ts } from "../util/json-2ts";
import {
  copyObjectWithoutNullsOrGraphQLMetaKeys
} from "../util/object-utils";
import {
  TemporalContext
} from "./temporal.reducers";
import { UserContext } from "./user.reducers";

import { LS_PREFIX } from "../util/string-constants";

const config = {
  keys: [
    'reference',
    {
      user: {
        deserialize: (state: any) => {
          const ret = {
            ...state,
            profile: User.ToValidContextObject(state.profile),
            lastActiveTimestamp: new Date(state.lastActiveTimestamp)
          };
          return ret;
        },
        serialize: (state: UserContext) => {
          if (null == state) {
            return null;
          }
          const ret = {
            ...state
          };

          const profile = User.ToValidContextObject(state.profile);
          if (null == profile) {
            ret.profile = null;
          } else {
            ret.profile = {
              ...profile,
              Settings: profile.Settings ? profile.Settings.serialize() : {},
              Features: profile.Features ? profile.Features.serialize() : {},
              Preferences: profile.Preferences ? profile.Preferences.serialize() : {}
            };
          }

          return ret;
        }
      }
    },
    'ux',
    'demo',
    'dimension',
    {
      temporal: {
        filter: ['range', 'rangesByType', 'aggregation', 'L4L'],
        deserialize: (data: any) => {
          const ret = TemporalContext.ToValidContextObject(data);
          return ret;
        }
      }
    },
    {
      serialize: (toClean: object) => copyObjectWithoutNullsOrGraphQLMetaKeys(toClean)
    }
  ],
  rehydrate: true,
  storageKeySerializer: (key: string) => LS_PREFIX + key
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync(config)(reducer);
}
