import { Injectable } from '@angular/core';
import { Logger } from "../logging/logger";
import * as jsPDF from 'jspdf';
import * as domtoimage from 'dom-to-image';
import { COMMA } from '../../util/string-constants';
import { 
  EMPTY,
  SPACE,
  DASH,
  CLASS,
  DIV_TAG,
  WHITE,
  LOCATION_ID,
  LOCATION_TYPE_ID,
  REGION_ID,
  ENTITY_ID 
} from '../../util/string-constants';

const DEFAULT_SCALE = 2;
const DASHBOARD = "Dashboard";
const FMT_A4 = "a4";
const IMG_FMT_PNG = "PNG";
const PORTRAIT = "p";
const SELECTOR_ACTIVE_SIDEBAR = '#sidebar-dashboard .active';
const SELECTOR_TEMPORAL_DETAILS = '.temporal-display-container';
const TAG_ANCHOR = "a";
const TAG_DDC_COMPONENT_CONTAINER = 'ddc-component-container';
const TRANSFORM = 'scale('+DEFAULT_SCALE+')';
const TRANSFORM_ORIGIN = 'top left';
const UNIT_MM = "mm";
const DEFAULT_WIDTH_OFFSET = 5;
const DEFAULT_HEIGHT_OFFSET = 10;
const DEFAULT_DASHBOARD_FILENAME = "dashboard";
const DEFAULT_IMAGE_FILENAME = "ddc-image.jpeg";
const FONT_FAMILY = "helvetica";
const FONT_STYLE = "normal";
const FONT_SIZE = 10;
const CENTER = "center";
const WIDTH_THRESHOLD = 85;
const IMAGE_QUALITY = 1;
const CSS_CLASS_DDC_GROUP = ".ddc-group";
const LOCATIONS = "Locations"
const LOCATION_TYPES = "Location Types";
const AREAS = "Areas";
const ENTITIES = "Entities";
const LOCATIONS_SELECTOR = '.select2-hidden-accessible [label="Locations"]';
const AREAS_SELECTOR = '.select2-hidden-accessible [label="Areas"]';
const LOCATION_TYPES_SELECTOR  = '.select2-hidden-accessible [label="Location Types"]';
const ENTITIES_SELECTOR  = '.select2-hidden-accessible [label="Entities"]';
const REFERENCE_CONTEXT_KEY = 'ddc.context.reference';
const BLOB = 'blob';
const HUNDRED = 100;

@Injectable()
export class ExporterService {
  private _logger: Logger;

  public async exportPDF(){
    let htmlNodes = this._getAllNodes();
    let base64EncodedImages = this._getDOMImages(htmlNodes);
    let imageUrls = await Promise.all(base64EncodedImages);
    let images = this._createHTMLImgs(imageUrls);
    let layout = this._getPageLayout(htmlNodes);
    this._generatePDFWithImages(htmlNodes, images, layout);
  }

  public async exportImage(domElement: Element) {
    let image = await Promise.all(this._getDOMImages([domElement]));
    let link = document.createElement(TAG_ANCHOR);
    link.download = DEFAULT_IMAGE_FILENAME;
    link.href = image[0];
    link.click();
  }

  private _getAllNodes(): Element[]{
    let nodes = document.querySelectorAll(TAG_DDC_COMPONENT_CONTAINER);
    let node_list = Object.values(nodes);
    // Ignoring the temporal selector components.
    node_list.shift();
    return node_list;
  }
  
  private _getDOMImages(nodes: Element[]): Array<string>{
    let base64EncodedImages: Array<string> = [];
    nodes.map(element => {
      let domElement = element.children[0];
      return base64EncodedImages.push(this._convertDOMtoImage(domElement));
    });
    return base64EncodedImages;
  }

  private _convertDOMtoImage(domElement: Element) {
    return domtoimage.toJpeg(domElement, {quality: IMAGE_QUALITY,   bgcolor: WHITE,
      width: domElement.clientWidth * DEFAULT_SCALE,
      height: domElement.clientHeight * DEFAULT_SCALE, 
      style:{
        transform: TRANSFORM,
        transformOrigin: TRANSFORM_ORIGIN
    }});
  }

  private _createHTMLImgs(imgUrls: Array<string>): Array<HTMLImageElement> {
    return imgUrls.map(imgUrl => {
      let img = new Image();
      img.src = imgUrl;
      return img;
    });
  }

  private _convertPixelToMM(pixelVal: number) {
    return pixelVal * 0.2645833333;
  }
  private _getNewHeight(width:number, height:number, newWidth:number) {
    return (height/width) * newWidth;
  }

  private _getPageLayout(htmlNodes: Element[]): Array<number> {
    let layoutList: Array<number> = [];
    let i = 1;
    htmlNodes.forEach( htmlNode => {
      var colValString = htmlNode.closest(DIV_TAG).getAttribute(CLASS);
      if (htmlNode.closest(CSS_CLASS_DDC_GROUP)){
        colValString = htmlNode.closest(CSS_CLASS_DDC_GROUP).getAttribute(CLASS);
      }
      var colVal = this._getColValues(colValString);
      if (colVal) {
        layoutList.push(parseInt(colVal[1]));
      }
      i++;
    });
    return layoutList;
  }

  private _getColValues(colVal: string) {
    let xl_pattern = /\bcol-xl-(\w+)/g.exec(colVal);
    if (xl_pattern) {
      return xl_pattern;
    }
    let lg_pattern =  /\bcol-lg-(\w+)/g.exec(colVal);
    if (lg_pattern){
      return lg_pattern;
    }
  }

  private _generatePDFWithImages(htmlNodes: Array<Element>, images: Array<HTMLImageElement>, layout: Array<number>) {
    let doc = new jsPDF(PORTRAIT, UNIT_MM, FMT_A4);
    let pageWidth = doc.internal.pageSize.width - 10;
    let pageHeight = doc.internal.pageSize.height;
    let widthOffset = DEFAULT_WIDTH_OFFSET;
    let heightOffset = DEFAULT_HEIGHT_OFFSET;
    let ignoreNext = 0;
    this._addPageTitle(doc);
    htmlNodes.forEach( (htmlNode, index) => {
      // check if the node is part of the layout.
      // if so get sibling children and remove them from them html nodelist as they are arranged in sequential
      // order.
      if (htmlNode.closest(CSS_CLASS_DDC_GROUP) && ignoreNext == 0){
        let layoutChildren = htmlNode.closest(CSS_CLASS_DDC_GROUP).querySelectorAll(TAG_DDC_COMPONENT_CONTAINER);
        ignoreNext += layoutChildren.length;
      }
      let pDiv = htmlNode.children[0];
      let domHeight = this._convertPixelToMM(pDiv.clientHeight);
      let domWidth = this._convertPixelToMM(pDiv.clientWidth);
      let newDomWidth = pageWidth * (layout[index]/12);
      let newDomHeight = this._getNewHeight(domWidth, domHeight, newDomWidth);
      if (heightOffset + newDomHeight >= pageHeight) {
          doc.addPage();
          widthOffset = DEFAULT_WIDTH_OFFSET;
          heightOffset = DEFAULT_HEIGHT_OFFSET;
          this._addPageTitle(doc);
      }
      
      doc.addImage(images[index], IMG_FMT_PNG, widthOffset, heightOffset, newDomWidth, newDomHeight);
      
      if (ignoreNext == 1 && widthOffset+newDomWidth/pageWidth <= WIDTH_THRESHOLD/100) {
        heightOffset = DEFAULT_HEIGHT_OFFSET;
      }
      if (ignoreNext > 0) {
        heightOffset += newDomHeight;
        ignoreNext -= 1;
        if (ignoreNext == 0){
          if (widthOffset/pageWidth <= WIDTH_THRESHOLD/HUNDRED){ 
            heightOffset = DEFAULT_HEIGHT_OFFSET;
            widthOffset += newDomWidth;
          }
        }
      } else {
        widthOffset += newDomWidth;
      }
      if (widthOffset/pageWidth >= WIDTH_THRESHOLD/HUNDRED){
        widthOffset = DEFAULT_WIDTH_OFFSET;
        heightOffset += newDomHeight + 5;
      }
    });
    // doc.save(DEFAULT_DASHBOARD_FILENAME);
    doc.setProperties({
      title: DEFAULT_DASHBOARD_FILENAME
      });
    window.open(URL.createObjectURL(doc.output(BLOB)))
  }

  private _addPageTitle(doc) {
    let activeSideBar = document.querySelector(SELECTOR_ACTIVE_SIDEBAR);
    let perioddDetails = document.querySelector(SELECTOR_TEMPORAL_DETAILS);
    let title = activeSideBar ? activeSideBar.textContent + SPACE + DASHBOARD: DASHBOARD;
    let period = perioddDetails ? perioddDetails.textContent : EMPTY;
    let titleContent = title + SPACE + DASH + SPACE + period;
    let selectionInfo = this._getUserSelectionInfo();
    if (selectionInfo) {
      titleContent +=  SPACE + DASH + SPACE + selectionInfo;
    }
    doc.setFont(FONT_FAMILY);
    doc.setFontStyle(FONT_STYLE);
    doc.setFontSize(FONT_SIZE);
    doc.text(titleContent, 105, 7, null, null, CENTER);
  }

  private _getFullNameFromID(value, type){
    if (type == AREAS){
      return document.querySelectorAll(AREAS_SELECTOR)[0].querySelector('[value="regionID|'+value+'"]').textContent;
    }

    if (type == LOCATIONS){
      return document.querySelectorAll(LOCATIONS_SELECTOR)[0].querySelector('[value="locationID|'+value+'"]').textContent;
    }

    if (type == LOCATION_TYPES){
      return document.querySelectorAll(LOCATION_TYPES_SELECTOR)[0].querySelector('[value="locationTypeID|'+value+'"]').textContent;
    }

    if (type == ENTITIES) {
      return document.querySelectorAll(ENTITIES_SELECTOR)[0].querySelector('[value="entityID|'+value+'"]').textContent;
    }
  }

  private _getUserSelectionInfo(){
    let title = EMPTY;
    let referenceContext = localStorage.getItem(REFERENCE_CONTEXT_KEY);
    if (referenceContext) {
      referenceContext = JSON.parse(referenceContext);
      let locationID = referenceContext[LOCATION_ID];
      let locationTypeID = referenceContext[LOCATION_TYPE_ID];
      let entityID = referenceContext[ENTITY_ID];
      let regionID = referenceContext[REGION_ID];

      if (locationID.length > 0) {
        let regionName = this._getFullNameFromID(locationID[0], LOCATIONS); 
        if (regionName) {
          title = regionName;
          return title;
        }
      }
      
      if (locationTypeID.length > 0) {
        let locationTypeName = this._getFullNameFromID(locationTypeID[0], LOCATION_TYPES);
        if (locationTypeName) {
          title += locationTypeName;
        }
      }
      
      if (entityID.length > 0) {
        let entityName = this._getFullNameFromID(entityID[0], ENTITIES);
        if (title) {
          title += COMMA + SPACE + entityName;
        } else {
          title = entityName;
        }
      }
      
      if (regionID.length > 0) {
        let regionName = this._getFullNameFromID(regionID[0], AREAS);
        if (regionName) {
          if (title) {
            title += COMMA + SPACE + regionName
          } else {
            title = regionName;
          }
        }
        
      }
    }
    return title;
  }
}