import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType
} from "@ngrx/effects";
import { Store } from "@ngrx/store";
import {
  TranslateService
} from "@ngx-translate/core";
import {
  switchMap,
  withLatestFrom
} from "rxjs/operators";

import {
  PeriodOnPeriodType
} from "../model/business-dates/temporal-aggregation.enum";
import {
  SettingKey
} from "../model/user/setting-key.enum";
import {
  Logger
} from "../services/logging/logger";
import {
  LoggingService
} from "../services/logging/logging.service";
import {
  NotificationsService
} from "../services/notifications/notifications.service";
import { Context } from "./context";
import * as TemporalActions from "./temporal.actions";

const _TKEY_PREFIX = "UX.PLACEHOLDERS.LIKE-FOR-LIKE.";
const _TKEY_ON = _TKEY_PREFIX + "ON";
const _TKEY_OFF = _TKEY_PREFIX + "OFF";

@Injectable()
export class NotificationEffects {

  @Effect({ dispatch: false })
  public navigate$ = this._actions$
  .pipe(
    ofType(TemporalActions.L4L_CHANGED),
    withLatestFrom(this._store$),
    switchMap(async ([action, context]) => {
      const enabled = context.user.profile.Settings.setting(SettingKey.UXShowL4LChangedNotifications, false, true);
      if (!enabled) {
        this._logger.info("L4L Notifications disabled.");
        return;
      }

      let tKey: string = null;
      switch ((action as TemporalActions.L4LChanged).payload) {
        case PeriodOnPeriodType.YearAgoPeriod: {
          tKey = _TKEY_OFF;
          break;
        }
        case PeriodOnPeriodType.YearAgoPeriodL4L: {
          tKey = _TKEY_ON;
          break;
        }
      }
      if (null != tKey) {
        const translated = this._translationService.instant(tKey);
        // If the translated value is the translation key then there is no translation
        // so there should be no notification.
        if (translated !== tKey) {
          this._notificationsService.post({
            message: translated,
            hideAfter: 3
          });
        }
      }
    })
  );

  private _logger: Logger;

  constructor(
    private _actions$: Actions,
    private _store$: Store<Context>,
    private _notificationsService: NotificationsService,
    private _translationService: TranslateService,
    _loggingService: LoggingService
  ) {
    this._logger = new Logger("effects.notifications", _loggingService)
  }
}
