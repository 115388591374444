import { Injectable } from "@angular/core";
import {
  TranslateService
} from "@ngx-translate/core";

import * as Config from "../../util/config.constants";
import {
  formatedTimeString
} from "../../util/utils";
import {
  LocaleService
} from "../locale/locale.service";
import {
  Logger
} from "../logging/logger";
import {
  BusinessDateChangeBasedFormatter
} from "./business-date-change-based.formatter";
import {
  BusinessDateFormatter
} from "./business-date.formatter";
import { DateFormatter } from "./date-formatter";
import {
  DefaultExtentFormatter
} from "./default-extent-formatter";
import {
  DefaultFormatter
} from "./default-formatter";
import { IExtent } from "./i-extent.interface";
import {
  IFormatter
} from "./i-formatter.interface";
import {
  RoundedExtentFormatter
} from "./rounded-extent-formatter";
import {
  SimpleValueFormatter
} from "./simple-value-formatter";
import {
  ValueBasedFormatter
} from "./value-based-formatter";
import {
  PropertyBasedFormatter
} from "./property-based-formatter";
import { CompositeFormatter } from "./composite-formatter";
import { LoggingService } from "../logging/logging.service";

const VALID_CATEGORIES: string[] = [Config.FORMATTER, Config.EXTENT];

export interface FormatterDictionary {
  [id: string]: IFormatter;
}

@Injectable()
export class FormatterService {

  private _formatters: { [type: string]: any; } = {};

  private _formatterConfig: any = {
    default: {
      type: Config.DEFAULT
    }
  };

  private _formatterClassMap: {[type: string]: any; } = {
    dateFormat: DateFormatter,
    businessDateFormat: BusinessDateFormatter,
    businessDateChangeBasedFormat: BusinessDateChangeBasedFormatter,
    propertyBasedFormatter: PropertyBasedFormatter,
    compositeFormatter: CompositeFormatter,
    simpleValueFormat: SimpleValueFormatter,
    valueBasedFormatter: ValueBasedFormatter,
    default: DefaultFormatter
  };

  private _extentFormatters: { [type: string]: any; } = {};

  private _extentFormatterConfig: any = {
    default: {
      type: Config.DEFAULT
    }
  };

  private _extentFormatterClassMap: {[type: string]: any; } = {
    roundedExtent: RoundedExtentFormatter,
    default: DefaultExtentFormatter
  };
  private _logger: Logger;

  constructor(private _localeService: LocaleService, private _translateService: TranslateService, private _loggingService: LoggingService) {
    this._logger = new Logger("FormatterService", _loggingService);
  }

  public init(formatterConfig: any) {
    this._logger.info("Formatter service starts with formatterconfig of count '" + formatterConfig.length + "'", {config: formatterConfig});
    for (const fmt of formatterConfig) {
      const category: string = fmt.category || Config.FORMATTER;
      this._validateFormatterCategory(category);
      const fmtConfig = {
        id: fmt.id,
        type: fmt.type,
        // tslint:disable-next-line:object-literal-shorthand
        category: category
      };
      if (fmt.config) {
        fmtConfig[Config.CONFIG] = JSON.parse(fmt[Config.CONFIG]);
        fmtConfig[Config.CONFIG][Config.ID] = fmt.id;
      }
      this._getFormatterConfig(category)[fmt.id] =  fmtConfig;
    }
    this._logger.info("Completed Setting Config to Formatter Service " + formatedTimeString());
  }

  /*
  * This function will return the instance of the IFormatter.
  * @param string - data format type
  * @return - IFormatter instance
  */
  public formatter(code: string): IFormatter {
    code = null != code ? code : Config.DEFAULT;
    return this._instantiateFormatterBasedOnConfiguredCode(code);
  }

  /*
  * This function will return the instance of the IExtent.
  * @param string - data format type
  * @return - IExtent instance
  */
  public extent(code: string): IExtent {
      if (!code || code === "") {
        code = Config.DEFAULT;
      }
      return this._instantiateExtentFormatter(code);
  }

  public instantiateFormatter(formatterConfig: any): IFormatter {
    const formatterTypeName = formatterConfig[Config.TYPE];
    const formatterType = this._formatterTypeFromName(formatterTypeName);

    const logger = new Logger(formatterType.name, this._loggingService);
    this._logger.debug("Instantiating formatter service of type " + formatterTypeName);
    const instance: IFormatter = new formatterType(this._localeService.locale, formatterConfig.config, this._translateService, this, logger);
    instance.formatData = instance.formatData.bind(instance);
    return instance;

  }

  /*
  * This function will return the instance of IFormatter  by format type.
  * @param string - formatter type
  * @return - IFormatter
  */
  private _instantiateFormatterBasedOnConfiguredCode(code: string): IFormatter {
    if (null != this._formatters[code]) {
      return this._formatters[code];
    }

    const formatterConfig = this._formatterConfig[code];
    if (null == formatterConfig) {
      throw new Error("Formatter config '" + code + "' not found!");
    }
    const instance = this.instantiateFormatter(formatterConfig);
    this._formatters[code] = instance;
    return instance;
  }

  /*
  * This function will return the instance IExtent by extent format type.
  * @param string - extent formatter type
  * @return - IExtent
  */
  private _instantiateExtentFormatter(code: string): IExtent {
    if (null != this._extentFormatters[code]) {
      return this._extentFormatters[code];
    }

    const formatterConfig = this._extentFormatterConfig[code];
    if (null == formatterConfig) {
      throw new Error("Extent formatter config '" + code + "' not found!");
    }
    const type = formatterConfig.type;
    if (null == this._extentFormatterClassMap[type]) {
      throw new TypeError("Type '" + type + "' is not a registered extent formatter Type!");
    }
    const logWrapper = new Logger(this._extentFormatterClassMap[type].name, this._loggingService);
    const instance: IExtent = new this._extentFormatterClassMap[type](formatterConfig.config, logWrapper);
    this._extentFormatters[type] = instance;
    return instance;
  }

  private _getFormatterConfig(category: string): {} {
    switch (category) {
      case Config.FORMATTER:
        return this._formatterConfig;
      case Config.EXTENT:
        return this._extentFormatterConfig;
    }
  }

  private _formatterTypeFromName(type: string): any {
    if (null == this._formatterClassMap[type]) {
      throw new TypeError("Type '" + type + "' is not a registered formatter Type!");
    } else {
      return this._formatterClassMap[type];
    }
  }

  private _validateFormatterCategory(category: string) {
    if ((VALID_CATEGORIES.indexOf(category) === -1) ) {
      // tslint:disable-next-line:max-line-length
      throw new Error("Category '" + category + "' is not a valid category.  Categories must come from the the list [" + VALID_CATEGORIES.join(", ") + "] (not case sensitive)");
    }
  }
}
