import { Action } from "@ngrx/store";
import { Alert } from '../model/alert/alert.model';

/* tslint:disable max-classes-per-file */
export const ALERT_SELECTED = "ALERT_SELECTED";
export const ALERT_DESELECTED = "ALERT_DESELECTED";
export const UX_RESET = "UX_RESET";

export class AlertSelected implements Action {
  public readonly type = ALERT_SELECTED;
  constructor(public payload: Alert) {}
}

export class AlertDeSelected implements Action {
  public readonly type = ALERT_DESELECTED;
  constructor(public payload: Alert) {}
}

export class UXReset implements Action {
  public readonly type = UX_RESET;
  constructor(public context: any) {}
}

export type UXActions = AlertSelected | AlertDeSelected | UXReset;
/* tslint:enable max-classes-per-file */
