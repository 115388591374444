
import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType
} from "@ngrx/effects";
import { Store } from "@ngrx/store";
import {
  switchMap,
  withLatestFrom
} from "rxjs/operators";

import {
  BusinessDateRange
} from "../model/business-dates/business-date-range.model";
import {
  TemporalAggregation
} from "../model/business-dates/temporal-aggregation.enum";
import {
  Timestamp
} from "../model/timestamp.model";
import {
  TemporalService
} from "../services/temporal/temporal.service";
import {
  ContextConfiguration
} from "./config/context.config";
import { Context } from "./context";
import {
  BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED,
  BUSINESS_DATE_RANGE_CHANGED,
  BusinessDateRangeAndTemporalAggregationChangeComplete,
  BusinessDateRangeAndTemporalAggregationChanged,
  BusinessDateRangeChangeComplete,
  BusinessDateRangeChanged,
  CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED,
  CalendarDateRangeAndTemporalAggregationChangeComplete,
  CalendarDateRangeAndTemporalAggregationChanged,
  CurrentBusinessDateChangeComplete
} from "./temporal.actions";
import {
  AUTHENTICATION_COMPLETED
} from "./user.actions";
import { USER_RELOGIN } from "../util/string-constants"
import { _isActivityThresholdReached } from "./utils";

const _DEFAULT_TEMPORAL_AGGREGATION = TemporalAggregation.Week;
const _DEFAULT_BUSINESS_DATE_RANGE = {Last: {weeks: 0}, Next: {weeks: 0}};

@Injectable()
export class TemporalEffects {

  @Effect()
  public businessDateRangeAndTemporalAggregationChanged$ = this._action$
  .pipe(
    ofType(BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED),
    withLatestFrom(this._store$),
    switchMap(async ([action, context]) => {
      const config = ContextConfiguration.LOADED;
      const businessDateRange: BusinessDateRange = (action as BusinessDateRangeAndTemporalAggregationChanged).businessDateRange;
      const baselineBusinessDate = (action as BusinessDateRangeAndTemporalAggregationChanged).baselineBusinessDate || context.temporal.current;

      const temporalAggregation = (action as BusinessDateRangeAndTemporalAggregationChanged).temporalAggregation || context.temporal.aggregation;
      const convertToAbsolute = (action as BusinessDateRangeAndTemporalAggregationChanged).convertToAbsolute || false;

      const newRange = await this._temporalService.HydratedBusinessDateRanges(
        businessDateRange,
        config.temporalAggregations,
        baselineBusinessDate,
        convertToAbsolute
      );
      return new BusinessDateRangeAndTemporalAggregationChangeComplete(newRange, temporalAggregation);
    })
  );

  @Effect()
  public calendarDateRangeAndTemporalAggregationChanged$ = this._action$
  .pipe(
    ofType(CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED),
    withLatestFrom(this._store$),
    switchMap(async ([action, context]) => {
      const config = ContextConfiguration.LOADED;
      const timestamp: Timestamp = (action as CalendarDateRangeAndTemporalAggregationChanged).timestamp;

      const temporalAggregation = (action as CalendarDateRangeAndTemporalAggregationChanged).temporalAggregation || context.temporal.aggregation;

      const newRange = await this._temporalService.HydratedCalendarTimestamp(timestamp,
        config.temporalAggregations);
      return new CalendarDateRangeAndTemporalAggregationChangeComplete(newRange, temporalAggregation);
    })
  );

  @Effect()public init$ = this._action$
  .pipe(
    ofType(AUTHENTICATION_COMPLETED),
    withLatestFrom(this._store$),
    switchMap(async ([, context]) => {
      let rangeAndAggregation;
      const config = ContextConfiguration.LOADED;
      const userRelogin = localStorage.getItem(USER_RELOGIN);
      localStorage.removeItem(USER_RELOGIN);

      if (null != userRelogin && true === _isActivityThresholdReached(context.user)) {
        const newContext = context.user;
        const userProfile = newContext.profile;
        if (userProfile != null) {
          rangeAndAggregation = {...userProfile.DefaultContext.temporal};
        }
      } else {
        rangeAndAggregation = await this._getRangeAndAggregation(context);
      }
      const temporalContext = await this._temporalService.CurrentBusinessDate(
        rangeAndAggregation.range,
        rangeAndAggregation.aggregation,
        config.temporalAggregations
      );
      return new CurrentBusinessDateChangeComplete(temporalContext);
    })
  );

  @Effect()public businessDateRangeChanged$ =
  this._action$
  .pipe(
    ofType(BUSINESS_DATE_RANGE_CHANGED),
    withLatestFrom(this._store$),
    switchMap(async ([action, context]) => {
      const config = ContextConfiguration.LOADED;

      const newRanges = await this._temporalService.HydratedBusinessDateRanges(
        (action as BusinessDateRangeChanged).payload,
        config.temporalAggregations,
        context.temporal.current
      );
      return new BusinessDateRangeChangeComplete(newRanges);
    })
  );

  private config: ContextConfiguration;

  constructor(private _action$: Actions, private _temporalService: TemporalService, private _store$: Store<Context>) {}

  private async _getRangeAndAggregation(context: Context): Promise<{range: BusinessDateRange, aggregation: TemporalAggregation}> {

    const temporal = context.temporal;
    const ret: any = {
      range: temporal.range || context.user.profile.DefaultContext.temporal.range,
      aggregation: temporal.aggregation || context.user.profile.DefaultContext.temporal.aggregation
    };

    ret.range = ret.range || _DEFAULT_BUSINESS_DATE_RANGE;
    ret.aggregation = ret.aggregation || _DEFAULT_TEMPORAL_AGGREGATION;

    return ret;
  }

}
