import {
  BusinessDateOffset
} from "../model/business-dates/business-date-offset.model";
import {
  BusinessDateRange
} from "../model/business-dates/business-date-range.model";
import {
  BusinessDate
} from "../model/business-dates/business-date.model";
import {
  TemporalAggregation, PeriodOnPeriodType
} from "../model/business-dates/temporal-aggregation.enum";
import * as TemporalActions from "./temporal.actions";

const _ADJUST_4_WEEKS_FORWARDS: BusinessDateOffset = {weeks: 4};
const _ADJUST_0_WEEKS: BusinessDateOffset = { weeks: 0 };
const _DEFAULT_BUSINESS_DATE_RANGE: BusinessDateRange = {Last: _ADJUST_4_WEEKS_FORWARDS, Next: _ADJUST_0_WEEKS};

export interface BusinessDateRangeDictionary {
  [id: string]: BusinessDateRange;
}

export class TemporalContext {

  public static ToValidInputObject(
    toStrip: TemporalContext,
    removeFromToIfLastNext: boolean = true
  ): TemporalContext {
    if (null == toStrip) {
      return null;
    }
    const newRange: BusinessDateRange = BusinessDateRange.ToValidInputObject(toStrip.range);
    const ret: TemporalContext = {
      aggregation: toStrip.aggregation,
      current: BusinessDate.ToValidInputObject(toStrip.current),
      range: newRange,
      rangesByType: null,
      absolute: BusinessDateRange.ToValidInputObject(
        BusinessDateRange.toFromTo(toStrip.absolute),
        removeFromToIfLastNext
      ),
      valid: false,
      L4L: toStrip.L4L
    };
    ret.valid = TemporalContext.isValid(ret);
    delete ret.rangesByType;
    return ret;
  }

  public static ToValidContextObject(
    toStrip: TemporalContext
  ): TemporalContext {

    if (null == toStrip) {
      return null;
    }

    const newRange: BusinessDateRange = BusinessDateRange.ToValidContextObject(toStrip.range);
    const ret: TemporalContext = {
      aggregation: toStrip.aggregation,
      current: BusinessDate.ToValidContextObject(toStrip.current),
      range: newRange,
      absolute: BusinessDateRange.toFromTo(toStrip.absolute),
      rangesByType: BusinessDateRange.ToValidContextObjects(toStrip.rangesByType),
      valid: false,
      L4L: toStrip.L4L
    };
    ret.valid = TemporalContext.isValid(ret);
    return ret;
  }

  public static isValid(context: TemporalContext): boolean {
    return context != null && context.current != null && context.range != null;
  }

  public range: BusinessDateRange;
  public absolute: BusinessDateRange;
  public current: BusinessDate;
  public aggregation: TemporalAggregation;
  public valid: boolean;
  public rangesByType: BusinessDateRangeDictionary;
  public L4L: PeriodOnPeriodType;
}

const initialState: TemporalContext = {
  range: null,
  rangesByType: {},
  absolute: null,
  current: null,
  aggregation: null,
  valid: false,
  L4L: PeriodOnPeriodType.DEFAULT
};

/**
 * This is the NgRx reducer function for our "temporal context."
 * It's the main interaction point between the UI code and the underlying state.
 * @param state The initial state of the object.
 * @param action The action triggered.
 */
export function temporalReducer(state = initialState, action: TemporalActions.TemporalActions): TemporalContext {
  let ret: TemporalContext;
  let range: BusinessDateRange;

  switch (action.type) {
    case TemporalActions.L4L_CHANGED:
      ret = {
        ...state,
        L4L: action.payload
      };
      break;
    case TemporalActions.BUSINESS_DATE_RANGE_CHANGE_COMPLETE:
      range = action.payload[state.aggregation];
      ret = {
        ...state,
        range,
        absolute: BusinessDateRange.toFromTo(range),
        rangesByType: action.payload
      };
      break;

    case TemporalActions.BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGE_COMPLETE:
    case TemporalActions.CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGE_COMPLETE:

      range = action.businessDateRanges[action.temporalAggregation];
      ret = {
        ...state,
        // tslint:disable-next-line: object-literal-shorthand
        range: range,
        absolute: BusinessDateRange.toFromTo(range),
        aggregation: action.temporalAggregation,
        rangesByType: action.businessDateRanges
      };
      break;

    case TemporalActions.CURRENT_BUSINESS_DATE_CHANGE_COMPLETE:
    case TemporalActions.TEMPORAL_DEMO_RESET:
      ret = {
        // tslint:disable-next-line:max-line-length
        aggregation: action.payload.aggregation != null ? action.payload.aggregation : state.aggregation != null ? state.aggregation : TemporalAggregation.Week,
        range: action.payload.range,
        rangesByType: action.payload.rangesByType,
        absolute: BusinessDateRange.toFromTo(action.payload.range),
        current: action.payload.current != null ? action.payload.current : state.current,
        valid: false,
        L4L: state.L4L
      };
      break;
    default:
      ret = state;
  }

  ret.valid = TemporalContext.isValid(ret);
  return ret;
}
