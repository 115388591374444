import {
  Series
} from "../../dashboard/components/config/series";
import {
  IFormatter
} from "../formatter/i-formatter.interface";
import { TooltipBase } from "./base-tooltip";
import { BusinessDate } from "../../model/business-dates/business-date.model";

import * as GQL from "../../util/graphql-tags";
import { translateUsingSuffix } from "../../util/translation-helpers";

// const KEY = "key";
// const SERIES_DEFINITION = "seriesDefinition";
// const REGION = "Region";
// const LOCATION = "Location";
// const NAME = "name";
// const EMPTY_STRING = "";
// const COLON_SPACE = ": ";
// const SEMI_COLON = ";";
// const INLINE_CONTAINER_STYLE = "line-height: 1.8;width: 24px;height: 24px;text-align: center;border:1px solid; padding: 0px; margin: 0px;";
// const INLINE_TEXT_STYLE = "font-size: 14px;color:#555555";
// const SERIES = "series";
// const VALUE = "value";
// const DATA = "data";
// const COLON = ":";
// const GREY_COLOR = "#555555";
// const WHITE_COLOR = "#ffffff";
const ADDITIONAL_DATA = "additional-data";
const SERIES = "series";
const ORDER_BY_SERIES_KEY = "order-by-series-key";
const NO_VALUE = "NA";

export class StackedAreaTooltip extends TooltipBase {
  salesFormatter: IFormatter;
  formatters: {} = {};

  public generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating StackedArea Tooltip of data", {tooltipData: data, parameter: params});
    const dataKey = params["data-key"] ? params["data-key"] : "point";
    let businessDate: BusinessDate;
    try {
      businessDate = data["series"][0][dataKey][GQL.BUSINESS_DATE];
    } catch (e) {
      businessDate = data["series"][0]["data"][GQL.BUSINESS_DATE];
    }
    const formatterConfig = this._config.formatters;
    const headerFormatter = this._formatterService.formatter(formatterConfig.header);
    const header = headerFormatter.formatData(businessDate);
    this.salesFormatter = this._formatterService.formatter(formatterConfig.sales);
    let additionalValueFormatter: IFormatter;

    this._initializeFormatters(params.units);

    if (formatterConfig.additionalValue) {
      additionalValueFormatter = this._formatterService.formatter(formatterConfig.additionalValue);
    }

    let popupHTML = '<table style="width: 95%; max-width: 300px; font-variant: small-caps;">';

    if (this._config.showHeader) {
      popupHTML += '<thead><tr> <td class="store-popup-title" colspan="3"> ' + header + "</td></tr></thead>";
    }

    if (undefined === params["show-titles"] || params["show-titles"]) {
      popupHTML += `<tr class="store-popup-text" style="">
      <td class="value" colspan="2" style="color:#555555;text-align: left;"></td>
      <td class="value" colspan="2" style="color:#555555;text-align:right;">
        ${null != params.seriesLabel ? this._translateService.instant(params.seriesLabel) : "Sales"}
      </td>`;
      // If show-share key is not present, then it means we have to show or even if its true
      if (undefined === params["show-share"] || params["show-share"]) {
      popupHTML += `<td class="value" colspan="2" style="color:#555555;text-align:right;">
        ${null != params.shareLabel ? this._translateService.instant(params.shareLabel) : "Share"}
      </td>`;
      }
      popupHTML += `</tr>`;
    }

    const seriesDefinitions: Series[] = data.seriesDefinitions;
    let total: number;
    if (true === params["no-total"]) {
      total = data["series"].reduce((t: number, n: any) => t + n.value, 0);
    } else {
      total = data.series.length > 1 ? data["series"][data.series.length - 1]["value"] : undefined;
    }
    const legendClass = "legend-color-guide-line";
    if (null != data.meta) {
      const periodIndex = data.series[0].point.index;
      data.meta.forEach((metaDatum: any) => {
        metaDatum.value = metaDatum.values[periodIndex].y;
        if (metaDatum.type === "line") {
          popupHTML += this.generateAdditionalSeriesHTML(metaDatum, data, seriesDefinitions);
        } else {
          popupHTML += this.generateSeriesHTML(metaDatum, total, additionalValueFormatter,
            header, seriesDefinitions, legendClass, params);
        }
      });
    } else {
      const tooltipOrder = params[ORDER_BY_SERIES_KEY];
      if (tooltipOrder !== undefined && tooltipOrder.length > 0) {
        tooltipOrder.forEach((seriesKey: number) => {
          let values = data.series.filter((item: any) => {
            if (true === item.total) {
              return false;
            }
            return item.point.seriesKey === seriesKey;
          });
          if (values.length > 0) {
            values.forEach((value: any) => {
                popupHTML += this.generateSeriesHTML(value, total, additionalValueFormatter,
                      header, seriesDefinitions, legendClass, params);
            });
            } else {
              values = params.additionalData.filter((item: any) => item.seriesKey === seriesKey );
              if (values.length <= 0) {
                throw new Error("series and additional-data does not have seriesKey provided in " + ORDER_BY_SERIES_KEY);
              }
              values.forEach((value: any) => {
                popupHTML += this.generateAdditionalSeriesHTML(value, data, seriesDefinitions);
              });
            }
        });
      } else {
        if (params.additionalData != null) {
          params.additionalData.forEach((additionalSeries: any) => {
            popupHTML += this.generateAdditionalSeriesHTML(additionalSeries, data, seriesDefinitions);
          });
        }
        data.series.forEach((ele: any) => {
          popupHTML += this.generateSeriesHTML(ele, total, additionalValueFormatter, header, seriesDefinitions, legendClass, params);
        });
      }
    }

    popupHTML += "</tbody></table>";
    return popupHTML;
  }

  private getLegendClass(data: any) {
    switch (data.type) {
      case "line":
        if (data.seriesDefinition && data.seriesDefinition.cssClass === "dashed") {
          return "legend-color-guide-dotted-line";
        }
        return "legend-color-guide-line";
      case "area":
      return "legend-color-guide-area";
      case "tooltip":
      case "empty":
      return "";
      default:
      return "legend-color-guide-dotted-line";
    }
  }

  private getUnitFormatter(seriesId: string) {

  }

  private generateAdditionalSeriesHTML(additionalSeries: any, data: any, seriesDefinitions: any) {
    if (additionalSeries["values"][data["value"]]) {
      const val = additionalSeries["values"][data["value"]]["y"];
      const additionalLegendClass = this.getLegendClass(additionalSeries);
      let popupHTML = '<tr class="store-popup-text"><td align="right" class="legend-icon '
      + additionalLegendClass + '" style="vertical-align:middle;"><div';
      popupHTML += ' style="color:' + additionalSeries["colour"] + '"></div></td>';
      popupHTML += '<td class="key" colspan="2" style="color:#555555;text-align: left;">' + additionalSeries["key"] + "</td>";
      popupHTML +=  '<td class="value" colspan="2" style="color:#555555;float:right;">';
      if (val !== null) {
        const seriesId = seriesDefinitions.find((sd: any) => sd.seriesKey === additionalSeries.seriesKey).referenceSeriesID;
        popupHTML += this._getFormattedValue(val, seriesId);
      } else {
        popupHTML += NO_VALUE;
      }
      popupHTML += "</td></tr>";
      return popupHTML;
    }
  }

  private generateSeriesHTML(ele: any, total: any, additionalValueFormatter: any, header: any,
                             seriesDefinitions: any, legendClass: any, params: any) {
    let val = ele["value"];
    if (params["show-null-values"] === false && null == val) {
      return "";
    }
    const share: any = total ? additionalValueFormatter.formatData((val / total) * 100) : NO_VALUE;
    if (null != ele.data && null != params.units) {
      const seriesId = ele.data.seriesDefinition.seriesID;
      val = this._getFormattedValue(val, seriesId);
    } else {
      val = this._getFormattedValue(val, undefined);
    }
    let key = ele["key"];
    const translatedKey = translateUsingSuffix(key, params.dimensionNameTranslationSuffix, this._translateService);
    if (key === "TOTAL") {
      key = "<b>" + key + " " + header + "</b>";
    }
    const seriesDefinition: Series = seriesDefinitions.find((seriesDef: Series) => {
      return seriesDef.translatedNameWithAxisHint === ele.key;
    });
    const seriesColour = ele["color"];
    let hilightedStyle = "";
    if (ele["highlight"]) {
      hilightedStyle = "border-bottom-style: solid; border-top-style: solid; border-width:1px; border-color:" + seriesColour + ";";
    }

    let popupHTML = '<tr class="store-popup-text" style="' + hilightedStyle + '">';
    if (this._config.showColorCode) {
      legendClass = "legend-color-guide-area";
    }
    let colorProperty = "background-color";
    if (ele["data"]) {
      legendClass = this.getLegendClass(ele["data"]);
      colorProperty = ele["data"]["type"] === "line" ? "color" : "background-color";
    }

    popupHTML += '<td align="right" class="legend-icon ' + legendClass + '" style="vertical-align:middle;"><div';

    popupHTML += ` style="${colorProperty}:${seriesColour}"></div></td>`;

    if (this._config.showLabel) {
      popupHTML += '<td class="key" colspan="2" style="font-variant: small-caps;color:#555555;text-align: left;">' + translatedKey + "</td>";
    }

    popupHTML +=  '<td class="value" colspan="2" style="color:#555555;text-align:right;">';
    popupHTML += val !== null ? val : NO_VALUE;
    if (undefined === params["show-share"] || params["show-share"]) {
      popupHTML += '<td colspan="2" class="value" style="color:#555555;text-align:right;">' + share + "</td>";
    }

    popupHTML += "</td></tr>";
    return popupHTML;
  }

  private _getFormattedValue(value: string, seriesId: string) {
    if (null != seriesId) {
      return this.formatters[seriesId] ? this.formatters[seriesId].formatData(value) :
        this.formatters["default"] ? this.formatters["default"].formatData(value) : this.salesFormatter.formatData(value);
    } else {
      return this.salesFormatter.formatData(value);
    }
  }

  private _initializeFormatters(units: {}) {
    if (units) {
      Object.keys(units).forEach((seriesId) => {
        this.formatters[seriesId] = this._formatterService.formatter(units[seriesId]);
      });
    }
  }
}
