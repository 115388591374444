import * as OU from "../util/object-utils";
import { ADD, CLEAR, IDimension, IDimensionPayload, IDs, REMOVE, REPLACE } from "./dimension.actions";
import * as DimensionActions from "./dimension.actions";

const LEVEL =  "level";

export interface DimensionContext {
  product: IDimension[];
  upsell: IDimension[];
}

const initialState: DimensionContext = {
  product: [],
  upsell: []
};

export function dimensionReducer(state = initialState, action: DimensionActions.DimensionActions): DimensionContext {
  switch (action.type) {
    case DimensionActions.DIMENSION_AGGREGATIONS_CHANGED:
      return updateDimensionAggregations(state, action.payload); // This will extend the given filter with
    case DimensionActions.DIMENSION_AGGREGATIONS_DEMO_RESET:
      return OU.copyObjectWithoutGraphQLMetaKeys(action.context) as DimensionContext;
    case DimensionActions.UPDATE_DIMENSIONS:
      return updateDimensions(state, action.payload);
    default:
      return state;
  }
}

function updateDimensionAggregations(currentState: DimensionContext, payload: IDimensionPayload): DimensionContext {

  // Can't use Array.slice().  All that does is return a new array.  It doesn't clone the items
  // IN the array.  The code which follows would be manipulating the current state array as well
  // and hence when you try to compare old and new state you will never see anything has changed.
  const dimensionLevels = JSON.parse((null != currentState[payload.type] ? JSON.stringify(currentState[payload.type]) : "[]"));

  let levelIndex = -1;
  const level = dimensionLevels.find((ele: any) => {
    levelIndex++;
    if (ele[LEVEL] === payload.level) {
      return ele;
    }
  });

  if (level === undefined) {
    dimensionLevels.push({
      level: payload.level,
      ids: [payload.id]
    });
  } else {
    const index = level[IDs].indexOf(payload.id);
    if (payload.type === ADD) {
      // If we don't find the element, we will add, else ignore.
      if (index === -1) {
        level[IDs].push(payload.id);
      }
    } else if (payload.type === REMOVE) {
      // If we find the element, we delete, else ignore.
      if (index > -1) {
        level[IDs].splice(index, 1);
      }
      if (level[IDs].length === 0) {
        dimensionLevels.splice(levelIndex, 1);
      }
    } else if (payload.type === REPLACE) {
      level[IDs][0] = payload.id;
    }
  }

  return {
    ...currentState,
    [payload.type]: dimensionLevels
  };
}

function updateDimensions(currentState: DimensionContext, payload: any): DimensionContext {
  // Can't use Array.slice().  All that does is return a new array.  It doesn't clone the items
  // IN the array.  The code which follows would be manipulating the current state array as well
  // and hence when you try to compare old and new state you will never see anything has changed.
  const currentDimensionContext = JSON.parse(JSON.stringify(currentState[payload.type]));
  const payloadLevels: any = {};
  payload.actions.forEach((level: any) => {
    const template = { ...level.template };
    const data = level.data;
    if (data === undefined) {
      return;
    }
    const levelEle = currentDimensionContext.find((ele: any) => {
      if (ele[LEVEL] === template.level) {
        return ele;
      }
    });
    switch (template.action) {
      case REPLACE: {
        delete template.action;
        template.ids = (data && levelEle && levelEle.ids.indexOf(data) !== -1 && template.toggle) ? [] : [data];
        delete template.toggle;
        payloadLevels[template.level] = template;
        break;
      }
      case ADD: {
        delete template.action;
        if (data && levelEle) {
          const index = levelEle[IDs].indexOf(data);
          if (index === -1) {
            levelEle.ids.push(data);
          } else if (template.toggle) {
            levelEle[IDs].splice(index, 1);
          }
          delete template.toggle;
          payloadLevels[template.level] = levelEle;
        } else {
          delete template.toggle;
          template.ids = data ? [data] : [];
          payloadLevels[template.level] = template;
        }
        break;
      }
      case CLEAR: {
        delete template.action;
        template.ids = [];
        payloadLevels[template.level] = template;
      }
      default: {
        // Throw Error
      }
    }
  });
  // Compare Two Arrays and Adjust Missing to currentDimensionContext
  const missingDimensionContext = currentDimensionContext.filter(((ele: any) => payloadLevels[ele.level] === undefined));
  const payloadkeys = Object.keys(payloadLevels).filter((key: any) => payloadLevels[key].ids.length > 0);
  return {
    ...currentState,
    [payload.type]: missingDimensionContext.concat(payloadkeys.map((key: any) => payloadLevels[key]))
  };
}
