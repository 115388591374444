
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import gql from "graphql-tag";
import * as jp from "jsonpath";
import * as GQL from "../util/graphql-tags";

import {
  DataService
} from "../services/data/data.service";
import { copyObject } from "../util/object-utils";
import {
  AggregationDemoReset
} from "./aggregation.actions";
import * as fromContext from "./context";
import {
  DemoResetDemo,
  SetDemoState
} from "./demo.actions";
import {
  DimensionAggregationsDemoReset
} from "./dimension.actions";
import { Noop } from "./effects";
import {
  SetReferenceContext
} from "./reference.actions";
import {
  TemporalDemoReset
} from "./temporal.actions";
import {
  AUTHENTICATION_COMPLETED,
  AuthenticationCompleted
} from "./user.actions";
import { UXReset } from "./ux.actions";
import { MutationSuccessful } from './mutation.actions';
import { switchMap } from "rxjs/operators";

const _STRIP_DEFAULT_CONTEXT_TAGS = ["guid"];
const _JSON_PATH_DEFAULT_CONTEXT: string = "$..DefaultContext";
const _JSON_PATH_DEMO_ENABLED: string = "$..enabled";
const _RESET_DEMO = `
mutation resetDemo {
  Configuration: ResetDemo {
    enabled
  }
}`;
const _DEMO_SETTINGS_QUERY = `
query getDemoConfig {
  Configuration {
    Demo {
      enabled
    }
  }
}`;

export const DEMO_RESET = "DEMO_RESET";

interface DemoConfiguration {
  enabled?: boolean;
}

@Injectable()
export class DemoEffects {

  @Effect()
  public loginSuccessed$ = this._action$
  .pipe(
    ofType(AUTHENTICATION_COMPLETED),
    switchMap(async (action: AuthenticationCompleted) => {
    const enabled = await this._demoEnabled();
    return new SetDemoState(enabled);
    })
  );

  @Effect()
  public demoEffect$ = this._action$
  .pipe(
    ofType(DEMO_RESET),
    switchMap(async () => {

      const response: any = await this._resetDemo();
      if (response.reference) {
        this._store.dispatch(new SetReferenceContext(response.reference));
      }
      if (response.temporal) {
        this._store.dispatch(new TemporalDemoReset(response.temporal));
      }
      if (response.ux) {
        this._store.dispatch(new UXReset(response.ux));
      }
      if (response.dimension) {
        this._store.dispatch(new DimensionAggregationsDemoReset(response.dimension));
      }
      if (response.demo) {
        this._store.dispatch(new DemoResetDemo(response.demo));
      }
      return new MutationSuccessful();
    })
  );

  private _demoGQLData: DemoConfiguration;

  constructor(
    private _action$: Actions,
    private _dataService: DataService,
    private _store: Store<fromContext.Context>
  ) { }

  private async _demoEnabled(): Promise<boolean> {
    const data = await this._demoConfiguration();
    return jp.query(data, _JSON_PATH_DEMO_ENABLED)[0] as boolean;
  }

  private async _resetDemo(): Promise<DemoConfiguration> {
    const resp: any = await this._dataService.mutate("ResetDemo", _RESET_DEMO);
    return this._processDemoConfiguration(resp.data);
  }

  private async _demoConfiguration(): Promise<DemoConfiguration> {
    if (undefined !== this._demoGQLData) {
      return this._demoGQLData;
    }

    const queryOptions = {
      query: gql`${ _DEMO_SETTINGS_QUERY }`
    };

    const resp: any = await this._dataService.runQuery("DemoConfiguration", queryOptions);
    return this._processDemoConfiguration(resp.data);
  }

  private _processDemoConfiguration(data: any): DemoConfiguration {
    const ret: DemoConfiguration = {};
    ret[GQL.SCALAR_ENABLED] = jp.query(data, _JSON_PATH_DEMO_ENABLED)[0];
    this._demoGQLData = ret;
    return ret;
  }

}
