import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { QueryOptions } from "apollo-client";
import gql from "graphql-tag";
import * as jp from "jsonpath";

import * as fromContext from "../../context/context";
import * as fromTemporal from "../../context/temporal.reducers";
import {
  BusinessDateRange
} from "../../model/business-dates/business-date-range.model";
import {
  BusinessDate
} from "../../model/business-dates/business-date.model";
import {
  TemporalAggregation
} from "../../model/business-dates/temporal-aggregation.enum";
import {
  DataService
} from "../data/data.service";
import { BusinessDateRangeDictionary } from "../../context/temporal.reducers";
import { Timestamp } from "../../model/timestamp.model";

const _JSON_PATH_CURRENT_FISCAL_WEEK: string = "$..CurrentBusinessDate";
const _JSON_PATH_CURRENT_BUSINESS_DATE_RANGES: string  = "$..BusinessDateRanges[*]";
const _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES: string  = "$..HydratedBusinessDateRanges[*]";
const _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES_BY_CALENDAR_DATETIME: string  = "$..HydratedBusinessDateRangesByCalendarDateTime[*]";

const _CURRENT_BUSINESS_DATE_QUERY = gql`
query currentBusinessDate($businessDateRange: BusinessDateRangeInput, $temporalAggregations: [TemporalAggregationEnum!]!) {
  GroupEntity {
    guid
    CurrentBusinessDate {
      ..._businessDateDetails
      BusinessDateRanges(BusinessDateRange: $businessDateRange, TemporalAggregations: $temporalAggregations) {
        ..._hydratedBusinessDateRange
      }
    }
  }
}

fragment _hydratedBusinessDateRange on BusinessDateRange {
  Last {
    ..._offsetDetails
  }
  Next {
    ..._offsetDetails
  }
  From {
    ..._businessDateDetails
  }
  To {
    ..._businessDateDetails
  }
}

fragment _businessDateDetails on BusinessDate {
  guid
  type
  year
  quarter
  month
  week
  day
  CalendarDateRange {
    From {
      ..._calendarDetails
    }
    To {
      ..._calendarDetails
    }
  }
}

fragment _calendarDetails on DateTime {
  year
  month
  day
  asString
}

fragment _offsetDetails on BusinessDateOffset {
  years
  quarters
  months
  weeks
  days
}

`;

// tslint:disable:max-line-length
const _HYDRATE_BUSINESS_DATE_RANGE_QUERY = gql`
query hydrateBusinessDateRanges($businessDateRange: BusinessDateRangeInput!, $baselineBusinessDate: BusinessDateInput, $temporalAggregations: [TemporalAggregationEnum!]) {
  GroupEntity {
    guid
    Periods {
      HydratedBusinessDateRanges(BusinessDateRange: $businessDateRange, BaselineBusinessDate: $baselineBusinessDate, TemporalAggregations: $temporalAggregations) {
        Last {
          ..._offsetDetails
        }
        Next {
          ..._offsetDetails
        }
        From {
          ..._rangeDetails
        }
        To {
          ..._rangeDetails
        }
      }
    }
  }
}

fragment _rangeDetails on BusinessDate {
  guid
  type
  year
  quarter
  month
  week
  day
  CalendarDateRange {
    From {
      ..._calendarDetails
    }
    To {
      ..._calendarDetails
    }
  }
}

fragment _calendarDetails on DateTime {
  year
  month
  day
  asString
}

fragment _offsetDetails on BusinessDateOffset {
  years
  quarters
  months
  weeks
  days
}
`;
// tslint:enable:max-line-length

// tslint:disable:max-line-length
const _HYDRATE_CALENDAR_DATETIME = gql`
query hydrateCalendarDatetime($CalendarDateTime: DateTimeInput!, $temporalAggregations: [TemporalAggregationEnum!]) {
  GroupEntity {
    guid
    Periods {
      HydratedBusinessDateRangesByCalendarDateTime(CalendarDateTime: $CalendarDateTime, TemporalAggregations: $temporalAggregations) {
        From {
          ..._rangeDetails
        }
        To {
          ..._rangeDetails
        }
      }
    }
  }
}

fragment _rangeDetails on BusinessDate {
  guid
  type
  year
  quarter
  month
  week
  day
  CalendarDateRange {
    From {
      ..._calendarDetails
    }
    To {
      ..._calendarDetails
    }
  }
}

fragment _calendarDetails on DateTime {
  year
  month
  day
  asString
}
`;

@Injectable()
export class TemporalService {

  private _latestTemporalContext: fromTemporal.TemporalContext;
  public constructor(private _dataService: DataService, _store: Store<fromContext.Context>) {
    _store.select("temporal").subscribe((newContext: fromTemporal.TemporalContext) => {
      if (null != newContext) {
        this._latestTemporalContext = newContext;
      }
    });
  }

  public async HydratedBusinessDateRanges(
    businessDateRange: BusinessDateRange,
    temporalAggregations: TemporalAggregation[],
    baselineBusinessDate?: BusinessDate,
    convertToAbsolute: boolean = false
  ): Promise<BusinessDateRangeDictionary> {

    baselineBusinessDate = baselineBusinessDate != null
      ? baselineBusinessDate
      : this._latestTemporalContext != null
        ? this._latestTemporalContext.current
        : null;

    baselineBusinessDate = BusinessDate.ToValidInputObject(baselineBusinessDate);
    businessDateRange = BusinessDateRange.ToValidInputObject(businessDateRange);

    const queryOptions: QueryOptions = {
      query: _HYDRATE_BUSINESS_DATE_RANGE_QUERY,
      variables: {
        businessDateRange,
        temporalAggregations,
        baselineBusinessDate
      },
    };
    const response = await this._dataService.runQuery("TemporalService.HydratedBusinessDateRange", queryOptions);

    const businessDateRangesData = jp.query(response.data, _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES);
    return this._processBusinessDateRanges(businessDateRangesData, convertToAbsolute);

  }

  public async HydratedCalendarTimestamp(
    CalendarDateTime: Timestamp,
    temporalAggregations: TemporalAggregation[]
  ): Promise<BusinessDateRangeDictionary> {
    const queryOptions: QueryOptions = {
      query: _HYDRATE_CALENDAR_DATETIME,
      variables: {
        CalendarDateTime,
        temporalAggregations
      },
    };
    const response = await this._dataService.runQuery("TemporalService.HydratedBusinessDateRange", queryOptions);

    const businessDateRangesData = jp.query(response.data, _JSON_PATH_HYDRATED_BUSINESS_DATE_RANGES_BY_CALENDAR_DATETIME);
    return this._processBusinessDateRanges(businessDateRangesData, true);

  }

  public async CurrentBusinessDate(
    businessDateRange: BusinessDateRange,
    contextTemporalAggregation: TemporalAggregation,
    hydrateTemporalAggregations: TemporalAggregation[]
  ): Promise<fromTemporal.TemporalContext> {

    businessDateRange = BusinessDateRange.ToValidInputObject(businessDateRange);

    const queryOptions: QueryOptions = {
      query: _CURRENT_BUSINESS_DATE_QUERY,
      variables: {
        businessDateRange,
        temporalAggregations: hydrateTemporalAggregations
      }
    };

    const response = await this._dataService.runQuery("TemporalService.CurrentBusinessDate", queryOptions);
    let currentBusinessDate = jp.query(response.data, _JSON_PATH_CURRENT_FISCAL_WEEK)[0];
    const businessDateRangesData = jp.query(currentBusinessDate, _JSON_PATH_CURRENT_BUSINESS_DATE_RANGES);
    const businessDateRanges = this._processBusinessDateRanges(businessDateRangesData, false);

    currentBusinessDate = BusinessDate.ToValidContextObject(currentBusinessDate);
    const newContext: any = {
      aggregation: contextTemporalAggregation,
      current: currentBusinessDate,
      range: businessDateRanges[contextTemporalAggregation],
      rangesByType: businessDateRanges
    };
    return newContext;

  }

  private _processBusinessDateRanges(businessDateRanges: any, convertToAbsolute: boolean): {[id: string]: BusinessDateRange} {
    return businessDateRanges.reduce((dict: any, curr: any) => {
      const bd = BusinessDateRange.ToValidContextObject(curr, convertToAbsolute);
      dict[bd.From.type] = bd;
      return dict;
    }, {});
  }

}
