import {
  TranslateService
} from "@ngx-translate/core";

import * as Config from "../../util/config.constants";
import { Logger } from "../logging/logger";
import { BaseFormatter } from "./base-formatter";
import {
  FormatterService
} from "./formatter.service";

export class ValueBasedFormatter extends BaseFormatter {

  constructor(locale: d3.Locale, config: any, translateService: TranslateService, formatterService: FormatterService, logger: Logger) {

    super(locale, config, translateService, formatterService, logger);
    if (null == this.config) {
      // tslint:disable-next-line:max-line-length
      throw new Error("No configuration supplied for value based formatter!  Either configuration was missing or one of formatString / value option was not set!");
    }
  }

  public formatData(datum: any): string {
    for (const mapping of this.config[Config.VALUE_MAPPINGS]) {
      if (datum === mapping.value) {
        return this._translateService.instant(mapping[Config.TRANSLATION_KEY]);
      }
    }
    return null != this.config[Config.DEFAULT_TRANSLATION_KEY] ?
      this._translateService.instant(this.config[Config.DEFAULT_TRANSLATION_KEY]) : " ";
  }

}
