/* tslint:disable max-classes-per-file */

import { TemporalEffects } from "./temporal.effects";
import { DemoEffects } from "./demo.effects";
import { Action } from "@ngrx/store";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { AuthEffects } from "./auth.effects";
import { RouterEffects } from "./router.effects";
import { CommentEffects } from './comment.effects';
import { MutationEffects } from './mutation.effects';
import { SnapshotEffects } from './snapshot.effects';
import { switchMap } from "rxjs/operators";
import { NotificationEffects } from "./notification.effects";
import { ZendeskEffects } from "./zendesk-effects";

export const NOOP = "NOOP";

export class Noop implements Action {
  public readonly type = NOOP;
}

@Injectable()
export class NoopEffects {

  @Effect({dispatch: false})
  public noopEffect$ = this._action$
  .pipe(
    ofType(NOOP),
    switchMap(() => of(null))
  );

  constructor(private _action$: Actions) {}
}

export const Effects: any = [
  TemporalEffects,
  DemoEffects,
  NoopEffects,
  AuthEffects,
  RouterEffects,
  CommentEffects,
  SnapshotEffects,
  MutationEffects,
  NotificationEffects,
  ZendeskEffects
];

/* tslint:enable max-classes-per-file */
