import { Action } from "@ngrx/store";

import { Auth, UserContext } from "./user.reducers";

/* tslint:disable max-classes-per-file */
export const AUTHENTICATION_VALIDATION_REQUIRED = "AUTHENTICATION_VALIDATION_REQUIRED";
export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";
export const AUTHENTICATION_SUCCEEDED = "AUTHENTICATION_SUCCEEDED";
export const AUTHENTICATION_COMPLETED = "AUTHENTICATION_COMPLETED";
export const USER_LOGIN_REQUIRED = "USER_LOGIN_REQUIRED";
export const USER_RESET = "USER_RESET";
export const AUTHENTICATION_REQUESTED = "AUTHENTICATION_REQUESTED";
export const ACTIVE_ROUTE_CHANGED = "ACTIVE_ROUTE_CHANGED";

export class ActiveRouteChanged implements Action {
  public readonly type = ACTIVE_ROUTE_CHANGED;
  constructor(public payload: string) {}
}

export class AuthenticationSucceeded implements Action {
  public readonly type = AUTHENTICATION_SUCCEEDED;
  constructor(public payload: Auth) {
  }
}

export class UserLoginRequired implements Action {
  public readonly type = USER_LOGIN_REQUIRED;
  constructor() {
  }
}

export class AuthenticationFailed implements Action {
  public readonly type = AUTHENTICATION_FAILED;
  constructor(public payload: string) {}
}

export class AuthenticationCompleted implements Action {
  public readonly type = AUTHENTICATION_COMPLETED;
  constructor(public payload: UserContext) {}
}

export class AuthenticationValidationRequired implements Action {
  public readonly type = AUTHENTICATION_VALIDATION_REQUIRED;
  constructor() {}
}

export class AuthenticationRequested implements Action {
  public readonly type = AUTHENTICATION_REQUESTED;
  constructor() {}
}

export class UserReset implements Action {
  public readonly type = USER_RESET;
  constructor(public payload?: UserContext, public forceClear: boolean = false) {}
}

export type UserActions = AuthenticationSucceeded | AuthenticationFailed
                          | AuthenticationCompleted | UserLoginRequired
                          | AuthenticationValidationRequired | UserReset
                          | AuthenticationRequested | ActiveRouteChanged;
/* tslint:enable max-classes-per-file */
