import { Injectable } from "@angular/core";
import {
  ApolloQueryResult,
  QueryOptions
} from "apollo-client";
import gql from "graphql-tag";

import {
  formatedTimeString
} from "../../util/utils";
import {
  DataService
} from "../data/data.service";
import { Logger } from "../logging/logger";
import {
  LoggingService
} from "../logging/logging.service";
import {
  DashboardSpecification
} from "./dashboard-specification.model";

const GQL_NODE_NAME_SPEC: string = "Specifications";
const GQL_NODE_NAME_CONFIG: string = "Configuration";
const GQL_NODE_NAME_DASH: string = "Dashboard";

const LIST_QUERY: string = `
query dashboardSpecs {
  Configuration {
    Dashboard {
      Specifications {
        id
        guid
        name
        icon
        enabled
      }
    }
  }
}`;

const SPEC_QUERY: string = `
query dashboardSpec($ids: [String!]) {
  Configuration {
    Dashboard {
      Specifications(ids: $ids) {
        id
        guid
        enabled
        title
        description
        ContainerSpecifications {
          Groups {
            groupID
            parent
            styles
            classes
          }
          Containers {
            id
            guid
            groupID
            includeInHeader
            Title {
                name
                Values{
                  key
                  context
                  formatter
                }
              }
            Components {
              id
              guid
              type
              target
              config
            }
            Classes {
              container
              Widget {
                section
                title
                body
                header
              }
              Components {
                componentID
                values
              }
            }
            Styles {
              container
              Widget {
                section
                title
                body
                header
              }
              Components {
                componentID
                values
              }
            }
            Controls {
              show
              close
              expand
              fullscreen
              reload
              settings
            }
          }
        }
      }
    }
  }
}`;

const LOG_NAMESPACE: string = "DashboardService";

@Injectable()
export class DashboardService {

  private _logger: Logger;

  constructor(private _dataService: DataService,  loggingService: LoggingService) {
    this._logger = new Logger(LOG_NAMESPACE, loggingService);
    this._logger.info("Instantiated Dashboard Service " + formatedTimeString());
  }

  public dashboardSpecifications(): Promise<DashboardSpecification[]> {
    const queryOptions: QueryOptions = {
      query: gql`${LIST_QUERY}`
    };
    return this._dataService.runQuery("DashBoard", queryOptions).then((response: ApolloQueryResult<any>) => {
      this._logger.debug("Got the response", {data: response});
      return response.data[GQL_NODE_NAME_CONFIG][GQL_NODE_NAME_DASH][GQL_NODE_NAME_SPEC]
      .filter((item: any) => item.enabled === true) as Promise<DashboardSpecification[]>;
    });
  }

  public dashboardSpecification(dashboardID: string): Promise<DashboardSpecification> {
    const queryOptions = {
      query: gql`${SPEC_QUERY}`,
      variables: {
        ids: [ dashboardID ]
      }
    };
    return this._dataService.runQuery(dashboardID, queryOptions).then((response: ApolloQueryResult<any>) => {
      this._logger.debug("Got the response for dashboard id '" + dashboardID + "'", {data: response});
      return response.data[GQL_NODE_NAME_CONFIG][GQL_NODE_NAME_DASH][GQL_NODE_NAME_SPEC][0] as Promise<DashboardSpecification>;
    });
  }
}
