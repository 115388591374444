import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import {
  MutationType
} from "../dashboard/components/config/mutation-type.enum";
import {
  Period
} from "../model/alert/period.model";
import {
  DataService
} from "../services/data/data.service";
import {
  copyObjectWithoutGraphQLMetaKeys
} from "../util/object-utils";
import { Noop } from "./effects";
import {
  ADD_COMMENT,
  AddComment,
  MutationSuccessful
} from "./mutation.actions";
import { Timestamp } from "../model/timestamp.model";
import { switchMap } from "rxjs/operators";

const _LOG_ID = "comment.effects";

// tslint:disable-next-line:max-line-length
const _ADD_COMMENT_GQL = `mutation ($authorID: String!, $primaryReference: ReferenceInput!, $additionalReferences: [ReferenceInput!], $timestamp:DateTimeInput!, $period: PeriodInput!, $comment:String!) {
  AddComment(Author: {id: $authorID}, PrimaryReference: $primaryReference, AdditionalReferences: $additionalReferences, Timestamp: $timestamp, Period: $period, comment: $comment) {
    guid
    id
    comment
    Author {
      id
      guid
      displayName
      avatarURL
    }
  }
}
`;

@Injectable()
export class CommentEffects {

  @Effect()
  public commentAdded$ = this._action$
  .pipe(
    ofType(ADD_COMMENT),
    switchMap(async (action: AddComment) => {
    return this.saveComment(action);
    })
  );

  constructor(private _action$: Actions, private _dataService: DataService) {}

  private async saveComment(action: AddComment): Promise<MutationSuccessful | Noop> {
    const cmt = action.payload;

    const params = {
      authorID: cmt.Author.id,
      primaryReference: cmt.InputReferences.Primary,
      additionalReferences: cmt.InputReferences.Additional.length > 0 ? cmt.InputReferences.Additional : null,
      timestamp: Timestamp.ToValidInputObject(cmt.Timestamp),
      period: (copyObjectWithoutGraphQLMetaKeys((cmt.Period || Period.NO_PERIOD))),
      comment: cmt.comment
    };

    try {
      await this._dataService.mutate(_LOG_ID, _ADD_COMMENT_GQL, params);
      console.log("COMMENT SAVED");

      return new MutationSuccessful(MutationType.Comment);
    } catch (err) {
      console.log(`COMMENT __NOT__ SAVED!:

      ${err}`);
      return new Noop();
    }
  }

}
