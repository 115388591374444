import {
  CMD_COPY,
  TEXT_AREA,
  EMPTY,
  ELLIPSIS,
  IFRAME,
  COMMA,
  TRANSFORM,
  LEFT_ROUND_BRACKET,
  RIGHT_ROUND_BRACKET
} from "./string-constants";

import { TSPAN, COLON_FIRST_CHILD, TITLE } from "./d3.constants";

const FRAME_CSS = `position:absolute;left:0;top:-100%;width:100%;
             height:100%;margin:1px 0 0;border:none;opacity:0;pointer-events:none;`;

/*
* Function that returns, MM:SS:MS
*/
export function formatedTimeString(): string {
    const dateNow = new Date();
    return dateNow.getMinutes().toString() + ":" +
                        dateNow.getSeconds().toString() + ":" +
                        dateNow.getMilliseconds().toString();
}

export function hashCode(toHash: string): number {
  let hash = 0;
  for (let i = 0; i < toHash.length; i++) {
      const character = toHash.charCodeAt(i);
      // tslint:disable:no-bitwise
      hash = ((hash << 5) - hash) + character;
      hash = hash & hash; // Convert to 32bit integer
      // tslint:enable:no-bitwise
    }
  return hash;
}

export function copyToClipboard(copyText: string): void {
  const el = document.createElement(TEXT_AREA) as HTMLTextAreaElement;
  el.value = copyText;
  document.body.appendChild(el);
  el.select();
  document.execCommand(CMD_COPY);
  document.body.removeChild(el);
}

/**
 * Shades the given color to darker or lighter version based on the shadeValue
 * Positive 0.0 - 1.0 ---> Lighter
 * Negative -0.1 - -1.0 ---> Darker
 * @param color hex code of a color
 * @param shadePercent Positive or Negative as described above
 */
export function shadeColor2(color: string, shadePercent: number) {
  const f = parseInt(color.slice(1), 16);
  const t = shadePercent < 0 ? 0 : 255;
  const p = shadePercent < 0 ? shadePercent * -1 : shadePercent;
  // tslint:disable-next-line:no-bitwise
  const R = f >> 16;
  // tslint:disable-next-line:no-bitwise
  const G = f >> 8 & 0x00FF;
  // tslint:disable-next-line:no-bitwise
  const B = f & 0x0000FF;
  // tslint:disable-next-line:max-line-length
  return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
}

/**
 * Returns Hex code of a given rgb variant
 * @param x - if the color is rgb(185, 146, 147) - 185 or 146 or 147 will be parameter for this function
 */
export function hex(x: any) {
  const hexDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"];
  return isNaN(x) ? "00" : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
}

/**
 * Returns Hex code of a given rgb color
 * @param rgb Ex parameter - rgb(185, 146, 147)
 */
export function rgb2hex(rgb: string): string {
  if (!rgb.startsWith("rgb")) {
    return rgb;
  }
  const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  return "#" + hex(match[1]) + hex(match[2]) + hex(match[3]);
}

/**
 * Adds ellipsis to an SVG TEXT node if the content won't fit
 * the available space (which is either the space of the text node OR
 * the forceWidth you pass in if you know better how much space there is.)
 */
export function d3Ellipsis(textNode: any, forceWidth: number = null) {
  let letters = textNode.text();
  const originalContent: string = letters;

  const totalLetters = letters.length;

  // If we don't let you FORCE a width then you'll likely assume the width of the element as currently
  // populated is acceptable, which you don't always want...
  const tWidth = forceWidth != null ? forceWidth : parseFloat(textNode.node().getBoundingClientRect().width);

  // Add some ellipsis (we'll remove them later if they aren't needed).
  const ellipsis: any = textNode.text(EMPTY).append(TSPAN).text(ELLIPSIS);
  const eWidth = parseFloat(ellipsis.node().getComputedTextLength());

  // Available width for the text is the width of the text node (or the supplied forced width)
  // minus the width of the ellipsis themselves.
  const width = tWidth - eWidth;

  // Add the original content as a TSPAN under the TEXT entry.
  const tspan: any = textNode.insert(TSPAN, COLON_FIRST_CHILD).text(letters);

  // Now check how wide that text is (without the ellipsis).
  let computedTextLength: number = tspan.node().getComputedTextLength();

  // As long as the computed width is wider than we are allowed and there are letters left
  // we can remove, we remove a letter, then trim (no point having a space and then ellipsis)
  // And we reset the text content of the TSPAN node.
  while (computedTextLength > width && letters.length) {
    letters = letters.slice(0, -1).trim();
    tspan.text(letters);

    // Check again to see how wide things are.
    computedTextLength = tspan.node().getComputedTextLength();
  }

  // If we managed to fit everything in then we don't need the ellipsis after all.
  if (letters.length === totalLetters) {
    ellipsis.remove();
  }

  // Add a title which will at least give you a mouse over full rendering of the supplied text.
  textNode.append(TITLE).text(originalContent);

}

export function observeElementResize(element: any, handler: any, delay?: number) {
  // Executes the handler whenever element-
  // 1. gets loaded,
  // 2. if delay parameter is passed, after that delay.
  // 3. Gets resized.
  const frame: any = document.createElement(IFRAME);
  const supportsPE = document["documentMode"] < 11 && "pointerEvents" in frame.style;

  frame.style.cssText = `${FRAME_CSS}${supportsPE ? "" : "visibility:hidden;"}`;
  element.appendChild(frame);
  frame.contentWindow.onresize = () => {
    handler(element);
  };
  handler(element);
  if (null != delay) {
    setTimeout(() => handler(element), delay);
  }
  return frame;
}

export function getTranslateXY(element: any): string[] {
  return element.attr(TRANSFORM).replace(RIGHT_ROUND_BRACKET, EMPTY).split(LEFT_ROUND_BRACKET)[1].split(COMMA);
}
