import {
  TranslateService
} from "@ngx-translate/core";
import * as d3 from "d3";

import { Logger } from "../logging/logger";
import { BaseFormatter } from "./base-formatter";
import {
  FormatterService
} from "./formatter.service";

export class DateFormatter extends BaseFormatter {

  constructor(locale: d3.Locale, config: any, translateService: TranslateService, formatterService: FormatterService, logger: Logger) {
    super(locale, config, translateService, formatterService, logger);
  }

  public formatData(datum: any, params?: any): string {
    const formatTime = this.locale.timeFormat(this.config.formatString);
    const ret = formatTime(new Date(datum));
    return this.translatePlaceholders(ret);
  }
}
