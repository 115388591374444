import { Injectable } from "@angular/core";
import {
  JwtHelperService
} from "@auth0/angular-jwt";
import { Store } from "@ngrx/store";
import {
  TranslateService
} from "@ngx-translate/core";
import Auth0Lock from "auth0-lock";

import * as fromContext from "../../context/context";
import {
  AuthenticationRequested,
  UserReset
} from "../../context/user.actions";
import {
  HEADER_BEARER,
  LS_PREFIX,
  EMPTY,
  COLON,
  DOUBLE_SLASH
} from "../../util/string-constants";
import {
  copyToClipboard,
  formatedTimeString
} from "../../util/utils";
import { Logger } from "../logging/logger";
import {
  LoggingService
} from "../logging/logging.service";
import {
  NotificationsService
} from "../notifications/notifications.service";
import { getAuth, getJWT } from "./auth-utils";

interface AuthConfiguration {
    clientID: string;
    domain: string;
    callbackURL: string;
    memberRouter: string[];
    responseType: string;
    scope: string;
    logoutURL: string;
}

const _LOG_NAMESPACE: string = "services.authentication";

const _TRANSLATE_UX_PLACEHOLDERS_PFX = "UX.PLACEHOLDERS.";
const _TRANSLATE_LOGOUT_OPTIONS_PFX = _TRANSLATE_UX_PLACEHOLDERS_PFX + "LOGOUT-OPTIONS.";
const _TRANSLATE_TITLE_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "TITLE";
const _TRANSLATE_CLEAR_CACHE_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "CLEAR-CACHE";
const _TRANSLATE_CLEAR_ALL_DATA_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "CLEAR-ALL-DATA";

export interface AuthenticationResult {
  isError: boolean;
  error?: any;
  auth?: any;
}

export interface AuthenticationCheckResult {
  isTokenExpired: boolean;
  auth?: any;
}

@Injectable()
export class AuthenticationService {

  private _authConfig: AuthConfiguration;
  private _lockOptions: {[id: string]: any};
  private _lock: any;
  private _logger: Logger;

  constructor(
    loggingService: LoggingService,
    private _store: Store<fromContext.Context>,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService
  ) {
    this._logger = new Logger(_LOG_NAMESPACE, loggingService);
  }

  public isAuthenticated(): AuthenticationCheckResult {
    const auth = getAuth();
    const token = getJWT();
    const isExpired = this.isTokenExpired(token);
    let ret;
    if (isExpired) {
      ret = {isTokenExpired: true};
    } else {
      ret = {isTokenExpired: false, auth};
    }
    return ret;
  }

  public waitForAuthenticationResult(): Promise<AuthenticationResult> {
    return new Promise((resolve, reject) => {
      this._lock.on("authenticated", (authResult: {[id: string]: any}) => {
        this._logger.warn("Authentication succeeded.");
        this._lock.hide();
        if (authResult && authResult.accessToken) {
          resolve({isError: false, auth: authResult});
        }
      });
      this._lock.on("authorization_error", (error: any) => {
        resolve({isError: true, error});
      });
    });
  }

  public login() {
    // console.log("SHOWING LOGIN SCREEN");
    this._lock.show();
  }

  public logout(event: MouseEvent | TouchEvent | boolean | undefined | null = null, token: string = null) {

    if (null == event) {
      this._performLogout();
    } else if (event instanceof MouseEvent) {
      this._handleLogoutClicked(event, token);
    } else {
      this._handleLogoutTapped(event, token);
    }
  }

  public init(authConfig: AuthConfiguration) {
    this._logger.info("Initialized Auth Service " + formatedTimeString());
    this._authConfig = authConfig;
    this._setAuth0LockOptions();
    this._instantiateAuth0Lock();
  }

  public isTokenExpired(token: string) {
    if (undefined === token) {
      return true;
    }
    const jwtHelper: JwtHelperService = new JwtHelperService();
    return jwtHelper.isTokenExpired(token);
  }

  public getUserProfile(accessToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._lock.getUserInfo(accessToken, (error: any, profile: any) => {
        if (error) {
          const errorMessage = `There was an error retrieving profile data.`;
          this._logger.error(errorMessage, error);
          resolve({
            isError: true,
            error: errorMessage + error.toString()
          });
        } else {
          this._logger.debug("Got user profile:", profile);
          resolve({
            isError: false,
            userProfile: profile
          });
        }
      });
    });
  }

  private _performLogout() {
    this._store.dispatch(new UserReset());
    const logoutURL = window.location.protocol + DOUBLE_SLASH + window.location.hostname +
                      ((window.location.port) ? COLON + window.location.port :  EMPTY)  + this._authConfig.logoutURL;
    this._lock.logout({returnTo: logoutURL});
  }

  private _handleLogoutTapped(event: TouchEvent | boolean, token: string): void {
    // For some obscure reason the long press code sometimes emits a touch event for a normal press
    // and undefined for a long press.
    // Other times it just emits true or false for whether it was a long press or not...

    let isLongPress: boolean;
    if (event === undefined || event === true) {
      isLongPress = true;
    } else {
      isLongPress = false;
    }

    if (isLongPress) {
      this._showLogoutOptions();
    } else {
      this._performLogout();
    }
  }

  private _handleLogoutClicked(event: MouseEvent, token: string) {
    if (this._copyAuthTokenToClipboardTriggered(event, token)) {
      copyToClipboard(`${HEADER_BEARER} ${token}`);
      console.log("Authentication token copied to clipboard.");
    } else if (this._showLogoutOptionsTriggered(event)) {
      this._showLogoutOptions();
    } else {
      this._performLogout();
    }
  }

  private _showLogoutOptions() {
    this._notificationsService.post({
      message: this._translateService.instant(_TRANSLATE_TITLE_KEY),
      showCloseButton: true,
      actions: {
        clearCache: {
          label: this._translateService.instant(_TRANSLATE_CLEAR_CACHE_KEY),
          action: () => this._clearAuthenticationAndLogout()
        },
        clearAll: {
          label: this._translateService.instant(_TRANSLATE_CLEAR_ALL_DATA_KEY),
          action: () => this._clearLocalStorageAndLogout()
        }
      }
    });
  }

  private _copyAuthTokenToClipboardTriggered(event: MouseEvent, token: string): boolean {
    return  (null != event.shiftKey && true === event.shiftKey)
    &&      (null != event.altKey && true === event.altKey)
    &&      (null != token);
  }

  private _showLogoutOptionsTriggered(event: MouseEvent): boolean {
    return (null != event.shiftKey && true === event.shiftKey);
  }

  private _clearAuthenticationAndLogout() {
    // Can't import from context files, creates circular dependency loop.
    localStorage.removeItem(`${LS_PREFIX}user`);
    location.reload(true);
  }

  private _clearLocalStorageAndLogout() {
    localStorage.clear();
    location.reload(true);
  }

  private _instantiateAuth0Lock(): void {
    this._logger.info("Initializing Auth0 Lock " + formatedTimeString());
    this._lock = new Auth0Lock(this._authConfig.clientID, this._authConfig.domain, this._lockOptions);
    this._lock.on("signin submit", () => {
      this._store.dispatch(new AuthenticationRequested());
    });
  }

  private _setAuth0LockOptions(): void {
    this._lockOptions = {
      autoclose: true,
      autofocus: true,
      closable: false,
      languageDictionary: {
        title: ""
      },
      theme: {
        logo: "/assets/img/didici_logo.png",
        primaryColor: "#754C99"
      },
      auth: {
        params: {
              scope: this._authConfig.scope
            },
            // for now I add the redirectUrl here later we can send the whole callbackURL from the config
        redirectUrl: window.location.protocol + "//" + window.location.hostname +
                    ((window.location.port) ? ":" + window.location.port :  "")  + this._authConfig.callbackURL,
        responseType: "token"
      }
    };
  }
}
