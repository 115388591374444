/* tslint:disable max-classes-per-file */

import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const GO = 'Navigate';
export const BACK = 'NavigateBack';
export const FORWARD = 'NavigateForward';
export const CHANGE_DASHBOARD = "NavigateChangeDashboard";

export class Go implements Action {
  public readonly  type = GO;

  constructor( public payload: { path: any[]; query?: object; options?: NavigationExtras; }) {
  }
}

export class ChangeDashboard implements Action {
  public readonly type = CHANGE_DASHBOARD;
  constructor(public payload: {dashboard: number, options?: NavigationExtras; }) {
    payload.options = payload.options || {replaceUrl: false};
  }
}

export class Back implements Action {
    public readonly  type = BACK;
}

export class Forward implements Action {
  public readonly type = FORWARD;
}

export type ValidRouterActions = ChangeDashboard | Go | Back | Forward;
