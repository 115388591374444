import {
  Inject,
  Injectable
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  ConsoleLoggerService,
  Level
} from "@ngx-toolkit/logger";
import { LogglyService } from "ngx-loggly-logger";

import { AppConfig } from "../../app.config";
import * as fromContext from "../../context/context";
import * as fromUser from "../../context/user.reducers";
import {
  User
} from "../../model/user/user.model";
import * as constants from "../../util/string-constants";
import { LOGGING_LEVEL } from "./logging.module";

const _LOGGLY_KEY = '972dc3b6-f275-4964-8d60-d047366af0a9';
const _TAG = 'Angular-logs';
const _NO_USER_NAME = "NA";
const _ENVIRONMENT = process.env.ENV;
const _PRODUCTION: string = "production";
const _PROD: string = "prod";

const _DEFAULT_LOG_LEVEL = Level.WARN;

@Injectable()
export class LoggingService extends ConsoleLoggerService {

  // Set a default name otherwise the user context on the loggly messages will cause a null error.
  private _userProfile: User = {displayName: _NO_USER_NAME};
  private _logglyLogLevel: number;
  private _logLevel: number;

  constructor(
    private _logglyLogger: LogglyService,
    private _store: Store<fromContext.Context>,
    private _appConfig: AppConfig
  ) {
    super(_DEFAULT_LOG_LEVEL);
    this._logLevel = _DEFAULT_LOG_LEVEL;

    this._initializeLoggly();
    this._subscribeToUser();
  }

  public info(message?: any, ...optionalParams: any[]) {
    if (this._shouldLog(Level.INFO)) {
      super.info(message, ...optionalParams);
    }
    this._logToLoggly(Level.INFO, message, ...optionalParams);
  }

  public debug(message?: any, ...optionalParams: any[]) {
    if (this._shouldLog(Level.DEBUG)) {
      super.debug(message, ...optionalParams);
    }
    this._logToLoggly(Level.DEBUG, message, ...optionalParams);
  }

  public warn(message?: any, ...optionalParams: any[]) {
    if (this._shouldLog(Level.WARN)) {
      super.warn(message, ...optionalParams);
    }
    this._logToLoggly(Level.WARN, message, ...optionalParams);
  }

  public error(message?: any, ...optionalParams: any[]) {
    if (this._shouldLog(Level.ERROR)) {
      super.error(message, ...optionalParams);
    }
    this._logToLoggly(Level.ERROR, message, ...optionalParams);
  }

  public log(message?: any, ...optionalParams: any[]) {
    if (this._shouldLog(Level.LOG)) {
      super.log(message, ...optionalParams);
    }
    this._logToLoggly(Level.LOG, message, ...optionalParams);
  }

  private _shouldLog(level: Level): boolean {
    return level <= this._logLevel;
  }

  private _initializeLoggly() {
    this._logglyLogLevel = this._appConfig.Configuration().defaultLogglyPushLevel;
    this._logglyLogger.push({
      logglyKey: _LOGGLY_KEY,
      sendConsoleErrors: true,
      tag: _TAG
    });
  }

  private _subscribeToUser() {
    this._store.select(constants.CONTEXT_USER).subscribe((newContext: fromUser.UserContext) => {
      if (null != newContext) {
       this._userProfile = newContext.profile;
      }
    });
  }

  private _logglyLogUserName(): string {
    return this._userProfile && this._userProfile.displayName ? this._userProfile.displayName : _NO_USER_NAME;
  }

  private _logToLoggly(level: Level, message?: any, ...optionalParams: any[]) {
    if ((_ENVIRONMENT === _PROD) || (_ENVIRONMENT === _PRODUCTION) || (true === this._appConfig.Configuration().logAllToLoggly)) {
      if (level <= this._logglyLogLevel) {
        const logglyLogData = {};
        logglyLogData[constants.CONTEXT_USER] = this._logglyLogUserName();
        logglyLogData[constants.MESSAGE] = message;
        logglyLogData[constants.LEVEL] = level;

        if (message && message[constants.NAMESPACE]) {
          logglyLogData[constants.NAMESPACE] = message[constants.NAMESPACE];
        }
        this._logglyLogger.push(logglyLogData);
      }
    }
  }
}
