import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType
} from "@ngrx/effects";
import gql from "graphql-tag";
import { of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { USER_RELOGIN, CONTINUE_ROUTE, LOGIN_ROUTE } from "../util/string-constants"

import {
  Features
} from "../model/user/features-model";
import {
  Preferences
} from "../model/user/preferences-model";
import {
  Settings
} from "../model/user/settings.model";
import { User } from "../model/user/user.model";
import {
  storeUserContext
} from "../services/auth/auth-utils";
import {
  AuthenticationService
} from "../services/auth/authentication.service";
import {
  DataService
} from "../services/data/data.service";
import {
  Logger
} from "../services/logging/logger";
import {
  LoggingService
} from "../services/logging/logging.service";
import {
  APP_INITIALIZED
} from "../services/startup/startup.service";
import {
  AUTHENTICATION_SUCCEEDED,
  AUTHENTICATION_VALIDATION_REQUIRED,
  AuthenticationCompleted,
  AuthenticationFailed,
  AuthenticationSucceeded,
  USER_LOGIN_REQUIRED,
  UserActions,
  UserLoginRequired
} from "./user.actions";
import { Router } from "@angular/router";
import { Noop } from "./effects";

export const LOGOUT = "LOGOUT";
const _DIDICI_NAMESPACE = "https://didici.io/";
const _USER_NAME = _DIDICI_NAMESPACE + "name";
const _PICTURE = _DIDICI_NAMESPACE + "picture";

const _USER_QUERY = gql`{ User { guid displayName avatarURL Settings Features Preferences DefaultContext} }`;
const _AUTH_EFFECTS_QUERY_ID = "AuthenticationEffectsUserQuery";

@Injectable()
export class AuthEffects {

  private _user: User;
  private _userProfile: any;

  /**
   * Runs when app is first initialized.
   */
  @Effect()
  public authenticationValidationRequired$ = this._action$
  .pipe(
    ofType(AUTHENTICATION_VALIDATION_REQUIRED),
    switchMap(() => {
    const res = this._authenticationService.isAuthenticated();
    this._logger.warn("isAuthenticated returned token expired: " + res.isTokenExpired);
    let returnAction: UserActions;
    if (res.isTokenExpired) {
      this._logger.warn("Issuing UserLoginRequired");
      returnAction = new UserLoginRequired();
      // Setting login flag, to be checked by AUTHENTICATION_COMPLETED temporal effect.
      localStorage.setItem(USER_RELOGIN, "true");
    } else {
      this._logger.warn("Issuing AuthenticationSucceeded");
      returnAction = new AuthenticationSucceeded(res.auth);
    }
    return Promise.resolve(returnAction);
    })
  );

  /**
   * Runs when authentication check during initialization indicates that
   * authentication is required (either the token is expired or doesn't exist.)
   * This is just an async callback effectively which dispatches the appropriate
   * action after the user has done authenticating.
   */
  @Effect()
  public authenticationRequired1$ = this._action$
  .pipe(
    ofType(APP_INITIALIZED),
    switchMap(async () => {
      this._logger.warn("Listening for authentication events from Auth0.");
      const res = await this._authenticationService.waitForAuthenticationResult();
      let returnAction: UserActions;
      if (!res || res.isError) {
        this._logger.warn("Auth0 returned an error.");
        console.log(res.error);
        returnAction = new AuthenticationFailed(res ? res.error : "NO ERROR DETAILS AVAILABLE.");
      } else {
        this._logger.warn("Auth0 returned success.");
        returnAction = new AuthenticationSucceeded({
          accessToken: res.auth.accessToken,
          idToken: res.auth.idToken
        });
      }
      return returnAction;
    })
  );

  /**
   * Also runs if authentication is required.  This just displays the login box and then returns nothing
   * (not dispatch: false indicating there isn't going to be anything to dispactch from this effect.)
   */
  @Effect({dispatch: false}) public userLoginRequired$ = this._action$
  .pipe(
    ofType(USER_LOGIN_REQUIRED),
    switchMap(() => {
      this._logger.warn("Displaying login box.");
      this._authenticationService.login();
      return of(null);
    })
  );

  /**
   * Runs after authentication has succeeded (from the async AUTHENTICATION_REQUIRED effect.)
   */
  @Effect() public authenticationSucceeded$ = this._action$
  .pipe(
    ofType(AUTHENTICATION_SUCCEEDED),
    switchMap(async (action: AuthenticationSucceeded) => {
      this._logger.warn("Received AuthenticationSucceeded event.");
      if (null == this._userProfile) {
        this._logger.warn("Retrieving user profile details from Auth0...");
        const res = await this._authenticationService.getUserProfile(action.payload.accessToken);
        if (res && !res.isError) {
          this._logger.warn("Received user profile.");
          this._userProfile = res.userProfile;
        } else {
          this._logger.error("Could not retrieve user profile data from Auth0!");
          return new Noop();
        }
      }

      if (null == this._user) {
        this._logger.warn("Retrieving user profile details from DDC API...");
        const result = await this._dataService.runQuery(_AUTH_EFFECTS_QUERY_ID, { query: _USER_QUERY });
        const data = result.data;
        const newUser = new User();
        newUser.id = this._userProfile.sub;
        newUser.email = this._userProfile.email;
        newUser.displayName = this._userProfile[_USER_NAME] ? this._userProfile[_USER_NAME] : this._userProfile.name;
        newUser.nickName = this._userProfile.nickname;
        if (null != data.User) {
          newUser.avatarURL = data.User.avatarURL;
          newUser.guid = data.User.guid;
          newUser.Features = new Features(data.User.Features);
          newUser.Settings = new Settings(data.User.Settings);
          newUser.Preferences = new Preferences(data.User.Preferences);
          newUser.DefaultContext = JSON.parse(data.User.DefaultContext);
        }
        if (!newUser.avatarURL) {
          newUser.avatarURL = this._userProfile[_PICTURE] ? this._userProfile[_PICTURE] : "assets/img/avatar.png";
        }
        this._user = newUser;
      }
      const userObj = this._user;

      const payload: any = { auth: { ...action.payload }, profile: userObj };
      // Save authentication details immediately so callbacks which cause total reloads still work when ngrx not yet initialized.
      storeUserContext(payload);
      if (!window.location.toString().endsWith(LOGIN_ROUTE)) {
        return new AuthenticationCompleted(payload);
      } else {
        // If you clicked the "pill" to authenticate rather than actually logging in, then the redirect to /continue never happens.
        this._router.navigateByUrl(CONTINUE_ROUTE);
        return new Noop();
      }
    })
  );

  @Effect({dispatch: false}) public logout = this._action$
  .pipe(
    ofType(LOGOUT),
    tap(() => {
      this._authenticationService.logout();
    })
  );

  private _logger: Logger;

  constructor(
    private _router: Router,
    private _action$: Actions,
    private _authenticationService: AuthenticationService,
    private _dataService: DataService,
    loggingService: LoggingService
  ) {
    this._logger = new Logger("context.effects.authentication", loggingService);
  }

}
