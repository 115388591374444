import {
  Logger
} from "../logging/logger";
import { BaseSelector } from "./base-selector";
import { BrandService } from "./brand.service";
import {
  ISelectorProperties
} from "./selector.properties.interface";
import { ThresholdSelectorConfiguration, SelectorConfiguration } from "./threshold-selector-config.interface";

export class ThresholdBasedSelector extends BaseSelector {

  protected _default: SelectorConfiguration;
  protected _missing: SelectorConfiguration;

  protected _thresholds: ThresholdSelectorConfiguration[];

  public constructor(_code: string, _config: any, _brandService: BrandService, _logger: Logger) {
    super(_code, _config, _brandService, _logger);
    this._setDefaults(_config);

    const thresholds: ThresholdSelectorConfiguration[] = this.config.thresholds;
    thresholds.forEach((threshold) => this._initializeItem(threshold));
    // Sort the thresholds in DESCENDING order so we process the highest
    // threshold first.  That way we can stop the second we get to one we match.
    // If we sorted them ascending we'd have to keep going in case we found
    // a higher threshold value that also matched which is inefficient.
    this._thresholds = thresholds.sort((a: ThresholdSelectorConfiguration, b: ThresholdSelectorConfiguration) => b.threshold - a.threshold);
  }

  public seriesColour(properties: ISelectorProperties): string {
    this._logger.info("Series colour of properties", {props: properties});
    let curr: ThresholdSelectorConfiguration;
    // If there isn't a value in the properties then we
    // return the missing value colour.
    if (!properties || properties.value == null) {
      return this._seriesColour(this._missing, properties);
    }

    let value: any = properties.value;
    const reverseSign = this.config["reverse-sign"];
    if (reverseSign && value !== 0) {
      value = value * -1;
    }
    if (!(value instanceof Number)) {
      value = parseFloat(value);
    }

    if (isNaN(value)) {
      return this._seriesColour(this._default, properties);
    }

    // OK, this is pretty simple.  Just loop round the thresholds and as soon
    // as we find one where properties.value >= threshold, return colour.
    // Works this simply because the thresholds are sorted descending.
    for (curr of this._thresholds) {
      if (value >= curr.threshold) {
        return this._seriesColour(curr, properties);
      }
    }

    // Nothing matched, so use default colour.
    return this._seriesColour(this._default, properties);
  }

  public styles(properties: ISelectorProperties): {[type: string]: any} {
    this._logger.info("Styles of properties", {props: properties});
    let curr: ThresholdSelectorConfiguration;
    if (!properties || !properties.value) {
      return this._default.styles;
    }

    let value: any = properties.value;
    if (!(value instanceof Number)) {
      value = parseFloat(value);
    }

    if (isNaN(value)) {
      return this.config.default[properties.type];
    }

    for (curr of this.config.thresholds) {
      if (value >= curr.threshold) {
        return curr[properties.type];
      }
    }
    return this.config.default[properties.type];
  }

  protected _setDefaults(config: any) {
    if (!config.default) {
      throw new TypeError(`default property missing from ThresholdBasedSelector configuration for code '${this.code}'!`);
    }
    if (config.default.colour == null && config.default.selectorID) {
      throw new TypeError(`default property missing both colour and selectorID properties for code '${this.code}'!`);
    }

    this._default = this._initializeItem(config.default);
    this._missing = config.missing ? this._initializeItem(config.missing) : this._default;

  }

  protected _initializeItem(itemConfig: SelectorConfiguration): SelectorConfiguration {
    if (itemConfig.selectorID != null) {
      try {
        itemConfig.selector = this._brandService.seriesColourSelector(itemConfig.selectorID);
      } catch (err) {
        // tslint:disable-next-line:max-line-length
        throw new TypeError(`Selector '${this.code}' references selectorID '${itemConfig.selectorID}' which could not be retrieved due to the following error: ${err}`);
      }
    } else if (itemConfig.colour == null) {
      // tslint:disable-next-line:max-line-length
      throw new TypeError(`Selector '${this.code}' contains configuration which is missing both a .colour AND a .selectorID property.  Remove the item or specify one of these configuration properties.`);
    }
    return itemConfig;
  }

  protected _seriesColour(item: SelectorConfiguration, properties: ISelectorProperties): string {
    if (item.selector != null) {
      return item.selector.seriesColour(properties);
    } else {
      return item.colour;
    }
  }
}
