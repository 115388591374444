import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from "@angular/router";

import { Logger } from "../logging/logger";
import {
  LoggingService
} from "../logging/logging.service";
import {
  LOGIN_ROUTE
} from "./../../util/string-constants";
import {
  AuthenticationService
} from "./authentication.service";

const _LOG_NAMESPACE = "AuthGuards";

@Injectable()
export class IsAuthenticatedGuard implements CanActivate, CanActivateChild {

  private _logger: Logger;

  constructor(loggingService: LoggingService, private _router: Router, private _authenticationService: AuthenticationService) {
    this._logger = new Logger(_LOG_NAMESPACE, loggingService);
  }

  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      const isAuthenticated = this._authenticationService.isAuthenticated();
      if (isAuthenticated.isTokenExpired) {
        this._logger.warn("Authentication guard doesn't think you are logged in.  Redirecting to login page.");
        this._router.navigateByUrl(LOGIN_ROUTE);
        return false;
      } else {
        return true;
      }
  }

  public async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      return this.canActivate(next, state);
    }
}
