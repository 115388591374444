import * as fromUser from '../../context/user.reducers';
import { LS_USER } from '../../util/string-constants';

/**
 *  The data layer needs to be able to be called BEFORE the ngrx state has been initialised because when the callback comes from Auth0
 * that is a full page reload, so things get initialized all over again.
 * So, to work around this (and it's an acknowledged hack but it's pretty harmless)
 * we write DIRECTLY to local storage and the data layer reads DIRECTLY from local storage.
 * Hence it's encapsulated into these two helper functions.
 */
function storeUserContext(auth: fromUser.UserContext) {
  localStorage.setItem(LS_USER, JSON.stringify(auth));
}

function getAuth(): fromUser.Auth {
  const userContext: any = JSON.parse(localStorage.getItem(LS_USER));
  return userContext ? userContext.auth : undefined;
}

function getJWT(): string {
  let jwtToken: string;
  const auth = getAuth();
  if (auth) {
    jwtToken = auth.idToken;
  }
  return jwtToken;
}

export {
  storeUserContext,
  getAuth,
  getJWT
};
