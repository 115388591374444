import { Injectable } from "@angular/core";

import {
  Logger
} from "../logging/logger";
import {
  AlertConfigSelector
} from "./alert-config-selector";
import {
  IndexBasedSelector
} from "./index-based-selector";
import { ISelector } from "./selector.interface";
import {
  SeriesBasedSelector
} from "./series-based-selector";
import { StyleSelector } from "./style-selector";
import {
  ThresholdBasedSelector
} from "./threshold-based-selector";
import {
  ThresholdExpressionBasedSelector
} from "./threshold-expression-based-selector";
import {
  DynamicColourSelector
} from "./dynamic-colour-selector";
import { LoggingService } from "../logging/logging.service";
import { ValueBasedStyleSelector } from "./value-based-style-selector";

const COLOURS = "colours";
const LINE_STYLES = "lineStyles";
const ALERTS = "alerts";
const CONFIG = "config";
const SELECTORS = "selectors";
const STYLES = "styles";

@Injectable()
export class BrandService {

  private _brandServiceConfig: any = {
    selectors: {}
  };

  private _selectorsClassMap: {[type: string]: any; } =
  {
    IndexBased: IndexBasedSelector,
    AlertBased: AlertConfigSelector,
    ThresholdBased: ThresholdBasedSelector,
    ThresholdExpressionBased: ThresholdExpressionBasedSelector,
    StyleBased: StyleSelector,
    SeriesBased: SeriesBasedSelector,
    DynamicColour: DynamicColourSelector,
    ValueBased: ValueBasedStyleSelector
  };

  private _selectors: {[type: string]: {[type: string]: ISelector}} = {};
  private _logger: Logger;
  private _mobileDevice: boolean = undefined;
  private _IOSDevice: boolean = undefined;
  private _isTouchDevice: boolean = undefined;

  constructor(private _loggingService: LoggingService) {
    this._logger = new Logger("BrandService", _loggingService);
    this._selectors[COLOURS] = {};
    this._selectors[LINE_STYLES] = {};
    this._selectors[ALERTS] = {};
    this._selectors[STYLES] = {};
  }

  public get isMobileDevice(): boolean {
    if (undefined === this._mobileDevice) {
      this._mobileDevice = this._isMobileDevice();
    }
    return this._mobileDevice;
  }

  public get isTouchDevice(): boolean {
    if (this._isTouchDevice === undefined) {
      try {
        document.createEvent("TouchEvent");
        this._isTouchDevice = true;
      } catch (e) {
        this._isTouchDevice = false;
      }
    }
    return this._isTouchDevice;
  }

  public get isIOSDevice(): boolean {
    if (undefined === this._IOSDevice) {
      const iDevices = new Set([
        'iPad Simulator',
        'iPhone Simulator',
        'iPad',
        'iPhone'
      ]);
      this._IOSDevice = false;
      if (!!navigator.platform) {
          this._IOSDevice = iDevices.has(navigator.platform);
      }
    }
    return this._IOSDevice;
  }

  public init(brandConfig: any) {

    this._logger.debug("BrandService Initialized with config", {config: brandConfig});
    for (const fmt of brandConfig) {
      const configId = fmt.id;

      if (!fmt.Registrations || fmt.Registrations.length === 0) {
        throw new Error("Registrations not fount for config " + configId);
      }

      const fmtConfig = {
        id: configId,
        type: fmt.type
      };

      if (fmt.config) {
        fmtConfig[CONFIG] = JSON.parse(fmt[CONFIG]);
      }

      for (const reg of fmt.Registrations) {
        if (reg.selector && !this._brandServiceConfig[SELECTORS][reg.selector]) {
          this._brandServiceConfig[SELECTORS][reg.selector] = {};
        }
        if (reg.selectorProperty && !this._brandServiceConfig[SELECTORS][reg.selector][reg.selectorProperty]) {
          this._brandServiceConfig[SELECTORS][reg.selector][reg.selectorProperty] = {};
        }
        this._brandServiceConfig[SELECTORS][reg.selector][reg.selectorProperty][configId] = fmtConfig;
      }
    }
  }

  /*
  * This function will return the instance of the IColourPalette.
  * @param string - colorPalette type
  * @return - IColourPalette instance
  */
  public seriesColourSelector(selectorCode: string): ISelector {
    this._logger.info("Series colour selector code '" + selectorCode + "'");
    if (this._selectors[COLOURS][selectorCode]) {
      return this._selectors[COLOURS][selectorCode];
    }
    if (!this._brandServiceConfig.selectors.series.colours[selectorCode]) {
      throw new Error("Series colour selector code '" + selectorCode + "' was not found in the brand configuration under selectors.series.colours!");
    }
    const ret = this._instantiateSelector(selectorCode, this._brandServiceConfig.selectors.series.colours[selectorCode]);
    this._selectors[COLOURS][selectorCode] = ret;
    return ret;
  }

  public seriesLineStyleSelector(selectorCode: string): ISelector {
    this._logger.info("Series line style selector code '" + selectorCode + "'");
    if (this._selectors[LINE_STYLES][selectorCode]) {
      return this._selectors[LINE_STYLES][selectorCode];
    }
    if (!this._brandServiceConfig.selectors.series.lineStyles[selectorCode]) {
      // tslint:disable-next-line:max-line-length
      throw new Error("Series line style selector code '" + selectorCode + "' was not found in the brand configuration under selectors.series.lineStyles!");
    }
    const ret = this._instantiateSelector(selectorCode, this._brandServiceConfig.selectors.series.lineStyles[selectorCode]);
    this._selectors[LINE_STYLES][selectorCode] = ret;
    return ret;
  }

  public alertSelector(alertCode: string): ISelector {
    this._logger.info(`Alert code '${alertCode}`);
    if (this._selectors[ALERTS][alertCode]) {
      return this._selectors[ALERTS][alertCode];
    }
    if (!this._brandServiceConfig.selectors[ALERTS][ALERTS][alertCode]) {
      // tslint:disable-next-line:max-line-length
      throw new Error(`Alert code '${alertCode}' was not found in the brand configuration under selectors.alerts.lineStyles!`);
    }

    const ret = this._instantiateSelector(alertCode, this._brandServiceConfig.selectors[ALERTS][ALERTS][alertCode]);
    this._selectors[ALERTS][alertCode] = ret;
    return ret;
  }

  public styleSelector(styleCode: string): ISelector {
    if (!styleCode || styleCode === "") {
      /* This would be some default table style that can be changed in future */
      styleCode = "table-1";
    }
    if (this._selectors[STYLES][styleCode]) {
      return this._selectors[STYLES][styleCode];
    }

    if (!this._brandServiceConfig.selectors[STYLES][STYLES][styleCode]) {
      // tslint:disable-next-line:max-line-length
      throw new Error("Style code '" + styleCode + "' was not found in the brand configuration under selectors.styles.styles");
    }

    const ret = this._instantiateSelector(styleCode, this._brandServiceConfig.selectors[STYLES][STYLES][styleCode]);
    this._selectors[STYLES][styleCode] = ret;
    return ret;
  }

  /*
  * This function will return the instance of IFormatter  by format type.
  * @param string - colorPaletteselector type
  * @return - ISelector
  */
  private _instantiateSelector(selectorCode: string, selectorConfig: any): ISelector {
    if (this._selectorsClassMap[selectorConfig.type] === undefined) {
      throw new TypeError("Type '" + selectorConfig.type + "' not found. ");
    }
    this._logger.info("Instantiating Brand service of type " + selectorConfig.type);
    const logWrapper = new Logger(this._selectorsClassMap[selectorConfig.type].name, this._loggingService);
    return new this._selectorsClassMap[selectorConfig.type](selectorCode, selectorConfig.config, this, logWrapper);
  }

  private _isMobileDevice(): boolean {
    // This regex is taken from http://detectmobilebrowser.com/mobile, its an   Open source mobile phone detection helper platform.
    const a = navigator.userAgent || navigator.vendor || (window as any).opera;
    // tslint:disable-next-line: max-line-length
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4));
  }
}
