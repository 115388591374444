import { JsonObject, JsonProperty } from "json2typescript";

import * as GQL from "../util/graphql-tags";
import { Order } from './order.enum';
import { json2ts } from "../util/json-2ts";

@JsonObject
export class Timestamp {

  public static toUTCDate(date: Date | string): Date {

    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    // This WORKS for when the date picker control returns a date with some random
    // hour.  It will take the date in the actually picked control, and it will
    // convert it to a date which represents MIDNIGHT on that date, UTC, which the browser
    // will then convert to a locale date object (so 1am on that date in BST for instance.)
    // When this is passed to other timestamp methods it will be correctly treated as the right
    // UTC date to pass in to back end queries because they use toJSON() to create the asString
    // property which turns the date into a UTC string (and hence you wind up at the right date
    // irrespective of the browser time zone.)
    // Note you MUST NOT use the getUTCXXX methods here.  If you do that the browser will use
    // whatever random hour the control assigned to the date and in some cases that will cause
    // the date to get changed to something OTHER than the user selected.
    // So we use the actual date (year, month and day) the user selected, we convert it to
    // midnight on that date IN UTC, and then we leave the browser to do it's thing since we
    // know we have set a date which is actually CORRECT now.
    // PS Javascript dates are AWFUL.
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
        0
      )
    );
  }

  public static now(): Timestamp {
    return Timestamp.fromDateObject(new Date(Date.now()));
  }

  public static fromAsStringProperty(asString: string): Timestamp {
    return Timestamp.fromDateObject(new Date(asString));
  }

  public static fromDateObject(date: Date): Timestamp {
    if (!date) {
      return;
    }

    return json2ts({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
      asString: date.toJSON()
    }, Timestamp);
  }

  public static ToValidInputObject(toStrip: Timestamp): Timestamp {
    if (null == toStrip) {
      return null;
    }

    const ret: any = JSON.parse(JSON.stringify(toStrip));
    if (null != ret) {
      ret[GQL.SCALAR_YEAR] = toStrip.year;
      ret[GQL.SCALAR_MONTH] = toStrip.month;
      ret[GQL.SCALAR_DAY] = toStrip.day;
      ret[GQL.SCALAR_HOUR] = toStrip.hour;
      ret[GQL.SCALAR_MINUTE] = toStrip.minute;
      ret[GQL.SCALAR_SECOND] = toStrip.second;
      ret[GQL.SCALAR_AS_STRING] = toStrip.asString;
      delete ret._year;
      delete ret._month;
      delete ret._day;
      delete ret._hour;
      delete ret._minute;
      delete ret._second;
      delete ret._date;
    }
    return ret;
  }

  public static compare(a: Timestamp, b: Timestamp, timestampSortOrder: Order): number {

    if (a !== null && b === null) {
      return timestampSortOrder;
    } else if (a === null && b !== null) {
      return -1 * timestampSortOrder;
    } else if (a === b === null) {
      return 0;
    }

    const ret = new Date(a.asString).getTime() - new Date(b.asString).getTime();
    return ret * timestampSortOrder;
  }

  @JsonProperty(GQL.SCALAR_AS_STRING, String, false)
  public asString: string = undefined;

  public get year(): number {
    return null != this._year ? this._year : this.asDate.getUTCFullYear();
  }

  public get month(): number {
    return null != this._month ? this._month : this.asDate.getUTCMonth();
  }

  public get day(): number {
    return null != this._day ? this._day : this.asDate.getUTCDay();
  }

  public get hour(): number {
    return null != this._hour ? this._hour : this.asDate.getUTCHours();
  }

  public get minute(): number {
    return null != this._minute ? this._minute : this.asDate.getUTCMinutes();
  }

  public get second(): number {
    return null != this._second ? this._second : this.asDate.getUTCSeconds();
  }

  public get asDate(): Date {
    if (null == this._date) {
      this._setDate();
    }
    return this._date;
  }

  private _date: Date = undefined;

  @JsonProperty(GQL.SCALAR_YEAR, Number, true)
  private _year: number = undefined;

  @JsonProperty(GQL.SCALAR_MONTH, Number, true)
  private _month: number = undefined;

  @JsonProperty(GQL.SCALAR_DAY, Number, true)
  private _day: number = undefined;

  @JsonProperty(GQL.SCALAR_HOUR, Number, true)
  private _hour: number = undefined;

  @JsonProperty(GQL.SCALAR_MINUTE, Number, true)
  private _minute: number = undefined;

  @JsonProperty(GQL.SCALAR_SECOND, Number, true)
  private _second: number = undefined;

  public constructor() {
    this.asString = new Date(Date.now()).toUTCString();
  }

  private _setDate(): void {
    this._date = new Date(this.asString);
  }

}
