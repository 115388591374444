
export enum TemporalAggregation {
  Year = "Year",
  year = Year,
  Quarter = "Quarter",
  quarter = Quarter,
  Month = "Month",
  month = Month,
  Week = "Week",
  week = Week,
  Day = "Day",
  day = Day,
  Hour = "Hour",
  hour = Hour,
  Minute = "Minute",
  minute = Minute,
  Second = "Second",
  second = Second,
  Last5Weeks = "Last5Weeks",
  DEFAULT = Week
}

export enum PeriodOnPeriodType {
  DEFAULT = "YearAgoPeriod",
  YearAgoPeriod = "YearAgoPeriod",
  YearAgoPeriodL4L = "YearAgoPeriodL4L",
  PriorPeriod = "PriorPeriod"
}

export enum TemporalAggregationGranularity {
  Year = 0,
  year = Year,
  Quarter = 1,
  quarter = Quarter,
  Month = 2,
  month = Month,
  Week = 4,
  week = Week,
  Day = 8,
  day = Day,
  Hour = 16,
  hour = Hour,
  Minute = 32,
  minute = Minute,
  Second = 64,
  second = Second,
  DEFAULT = Week
}
