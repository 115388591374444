import {
  TranslateService
} from "@ngx-translate/core";

import * as Config from "../../util/config.constants";
import { Logger } from "../logging/logger";
import { BaseFormatter } from "./base-formatter";
import {
  FormatterService
} from "./formatter.service";
import {
  IFormatter
} from "./i-formatter.interface";

const _NA_TRANSLATION_KEY = "na-translation-key";

export class CompositeFormatter extends BaseFormatter {

  private _compositionFormatters: IFormatter[];

  constructor(locale: d3.Locale, config: any, translateService: TranslateService, formatterService: FormatterService, logger: Logger) {
    super(locale, config, translateService, formatterService, logger);
    if (!this.config || !this.config[Config.COMPOSITIONS]) {
      // tslint:disable-next-line:max-line-length
      throw new Error(`No configuration supplied for compsoite formatter.  Entry ${Config.COMPOSITIONS} was missing from ${this.config}.  Cannot continue!`);
    }

    this._compositionFormatters = this.config[Config.COMPOSITIONS].reduce((list: IFormatter[], formatCode: string) => {
      const formatter = this._formatterService.formatter(formatCode);
      list.push(formatter);
      return list;
    }, []);

  }

  public formatData(datum: any, params?: any): string {
    return this._compositionFormatters.reduce((ret: string, curr: IFormatter, index: number) => {
      return ret + curr.formatData(datum, params);
    }, "");
  }

}
