import { SettingKey } from "../model/user/setting-key.enum";
import * as fromUser from "../context/user.reducers";

const DEFAULT_ACTIVITY_THRESHOLD_SECONDS: number = 5 * 60;


export function _isActivityThresholdReached(newContext: fromUser.UserContext) {

  if (null == newContext || null == newContext.profile || null == newContext.profile.Settings) {
    // We haven't got enough to be going on with here to actually decide, so we default to false
    // and assume another call with a more usable context causes another call.
    return false;
  }

  const activityThreshold = newContext.profile.Settings.setting(
    SettingKey.UXDefaultRouteActivityThreshold,
    false,
    DEFAULT_ACTIVITY_THRESHOLD_SECONDS
  );

  const currTime: number = new Date(Date.now()).getTime();
  const milliSecondActivityThreshold: number = activityThreshold * 1000;

  // If no last active timestamp then set to 1 millisecond older than threshold to trigger true return value.
  // tslint:disable-next-line: max-line-length
  const lastActiveTimestamp: number = newContext.lastActiveTimestamp ? newContext.lastActiveTimestamp.getTime() : currTime - (milliSecondActivityThreshold + 1);
  const milliSecondsSinceLastActive: number = currTime - lastActiveTimestamp;

  return milliSecondActivityThreshold > 0 && milliSecondsSinceLastActive - milliSecondActivityThreshold > 0 ? true : false;
}
